import AccountDropdown from 'components/AccountDropdown/AccountDropdown'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import ConnectButton from 'components/ConnectButton'
import { Position } from 'components/Popper/typpes'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React from 'react'
import styled from 'styled-components'

const UserMenu: React.FC<BoxProps & { dropdownPosition?: Position; offsetBottom?: number }> = ({
  dropdownPosition,
  offsetBottom,
  ...props
}) => {
  const { account } = useActiveWeb3React()

  return (
    <StyledUserMenuContainer {...props}>
      {!account ? (
        <ConnectButton scale="sm" />
      ) : (
        <AccountDropdown dropdownPosition={dropdownPosition} offsetBottom={offsetBottom} />
      )}
    </StyledUserMenuContainer>
  )
}

const StyledUserMenuContainer = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
`

export default React.memo(UserMenu)
