import ToastListener from 'contexts/ToastsContext/Listener'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import ResetCSS from 'style/ResetCSS'

import Providers from 'Providers'
import Updaters from 'Updaters'
import { useGlobalHook } from 'hooks/useGlobalHook'
import Layout from 'layout'
import GlobalStyle from '../style/Global'

import 'rc-slider/assets/index.css'

function GlobalHooks() {
  useGlobalHook()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="description" content="Earn yield by lending your assets or boost yield by leveraging strategies" />
        <meta name="theme-color" content="#1FC7D4" />
        <meta name="twitter:image" content="/images/hero.png" />

        <meta
          name="twitter:description"
          content="Earn yield by lending your assets or boost yield by leveraging strategies"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Gentoo Finance" />
        <meta property="twitter:image" content="/images/hero.png" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap" rel="stylesheet" />

        <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA}`} />

        <title>Gentoo Finance</title>
      </Head>
      <Providers store={store}>
        <GlobalHooks />
        <Updaters />
        <ResetCSS />
        <GlobalStyle />
        <PersistGate loading={null} persistor={persistor}>
          <App {...props} />
        </PersistGate>
      </Providers>

      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          
          gtag('config', '${process.env.NEXT_PUBLIC_GA}');
          `,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  return (
    <>
      <Layout {...pageProps.layoutProps}>
        <Component {...pageProps} />
      </Layout>
      <ToastListener />
    </>
  )
}

export default MyApp
