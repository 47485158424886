import BigNumber from 'bignumber.js'
import { ERC20_ABI, LIQUIDITY_POOL_ABI } from 'config/abi'
import { ChainIdEnum } from 'config/networks'
import { Pool } from 'config/types/pool'
import { chunk } from 'lodash'
import { multicallv2 } from 'utils/multicall'

export const fetchUserPoolsData = async (account: string, pools: Pool[], chainId: ChainIdEnum) => {
  const userData = await callUser(account, pools, chainId)

  return userData.map((item, index) => {
    const [rawSuppliedTokenBalance, rawTokenBalance, rawAllowance] = item
    const suppliedTokenBalance = new BigNumber(rawSuppliedTokenBalance)
    const tokenBalance = new BigNumber(rawTokenBalance)
    const allowance = new BigNumber(rawAllowance)
    return {
      code: pools[index].code,
      allowance: allowance.toJSON().toString(),
      tokenBalance: tokenBalance.toJSON().toString(),
      suppliedTokenBalance: suppliedTokenBalance.toJSON().toString(),
    }
  })
}

const fetchUserPoolDataCall = (account: string, pool: Pool, chainId: ChainIdEnum) => {
  const suppliedTokenContractAddress = pool.suppliedToken.address
  const tokenContractAddress = pool.token.address

  return [
    {
      address: suppliedTokenContractAddress,
      name: 'balanceOf',
      params: [account],
    },
    {
      address: tokenContractAddress,
      name: 'balanceOf',
      params: [account],
    },
    { address: tokenContractAddress, name: 'allowance', params: [account, suppliedTokenContractAddress] },
  ]
}

const callUser = async (account: string, pools: Pool[], chainId: ChainIdEnum): Promise<any[]> => {
  const poolCalls = pools.flatMap((pool) => fetchUserPoolDataCall(account, pool, chainId))
  const chunkSize = poolCalls.length / pools.length
  const poolMultiCallResult = await multicallv2([...LIQUIDITY_POOL_ABI, ...ERC20_ABI], poolCalls, chainId)
  return chunk(poolMultiCallResult, chunkSize)
}
