import { useMemo } from 'react'
import truncateHash from 'utils/truncateHash'
import { useBidName } from './useBidName'
import { useSidName } from './useSidName'

export enum UsernameType {
  Sid = 'Sid',
  Bid = 'Bid',
  Address = 'Address',
}

export const useUsername = (account: string) => {
  const { loading: sidLoading, sidName } = useSidName(account)
  const { loading: bidLoading, bidName } = useBidName(account)

  return useMemo(() => {
    if (!account || sidName === undefined || bidName === undefined || sidLoading || bidLoading)
      return {
        username: '',
        type: sidName ? UsernameType.Sid : bidName ? UsernameType.Bid : UsernameType.Address,
      }

    return {
      username: sidName ? sidName : bidName ? bidName : truncateHash(account),
      type: sidName ? UsernameType.Sid : bidName ? UsernameType.Bid : UsernameType.Address,
    }
  }, [sidName, bidName, sidLoading, bidLoading, account])
}

export default useUsername
