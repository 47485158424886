import { NextLinkFromReactRouter } from './NextLink'
import React from 'react'
import styled from 'styled-components'
import getExternalLinkProps from 'utils/getExternalLinkProps'
import Text from '../Text'
import { LinkProps } from './types'
import { css } from 'styled-components'

const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryBright};
    ${({ hoverUnderLine }) =>
      hoverUnderLine &&
      css`
        text-decoration: underline;
      `}
  }
`

const Link: React.FC<LinkProps> = ({ external, href, ...props }) => {
  const internalProps = external ? getExternalLinkProps() : {}
  return (
    <StyledLink as={external ? 'a' : NextLinkFromReactRouter} to={href} href={href} {...internalProps} {...props} />
  )
}

Link.defaultProps = {
  color: 'text',
}

export default Link
