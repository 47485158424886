import { Contract } from '@ethersproject/contracts'
import {
  ERC20_ABI,
  LIQUIDITY_POOL_ABI,
  MULTICALL_ABI,
  ORACLE_ABI,
  STRATEGY_BOX_ABI,
  WETH_GATEWAY_ABI,
} from 'config/abi'
import { Erc20, Multicall, Oracle, StrategyBox, WethGateway } from 'config/abi/types'
import { ChainIdEnum } from 'config/networks'
import { Strategy } from 'config/types/strategy'
import { useProviderOrSigner } from 'hooks/useProviderOrSigner'
import { useMemo } from 'react'
import { getLiquidityPoolAddress, getMulticallAddress, getOracleAddress, getWethGateway } from 'utils/addressHelpers'
import { getContract } from 'utils/contractHelpers'
import useActiveWeb3React from './useActiveWeb3React'

export function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T | null {
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible)
  const { chainId } = useActiveWeb3React()
  const canReturnContract = useMemo(() => address && ABI && providerOrSigner, [address, ABI, providerOrSigner])

  return useMemo(() => {
    if (!canReturnContract) return null
    try {
      return getContract({
        address,
        abi: ABI,
        signer: providerOrSigner,
        chainId,
      })
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, providerOrSigner, canReturnContract]) as T
}

export const useMulticallContract = () => {
  const { chainId } = useActiveWeb3React()
  return useContract<Multicall>(getMulticallAddress(chainId), MULTICALL_ABI, false)
}

export const useTokenContract = (tokenAddress?: string, withSignerIfPossible?: boolean) => {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export const useLiquidityPoolContract = (chainId: ChainIdEnum, withSignerIfPossible?: boolean) => {
  return useContract(getLiquidityPoolAddress(chainId), LIQUIDITY_POOL_ABI, withSignerIfPossible)
}

export const useStrategyBox = (strategy: Strategy, chainId: ChainIdEnum, withSignerIfPossible?: boolean) => {
  return useContract<StrategyBox>(strategy.address, STRATEGY_BOX_ABI, withSignerIfPossible)
}

export const useWethGateway = (chainId: ChainIdEnum, withSignerIfPossible?: boolean) => {
  return useContract<WethGateway>(getWethGateway(chainId), WETH_GATEWAY_ABI, withSignerIfPossible)
}

export const useOracleContract = (chainId: ChainIdEnum) => {
  return useContract<Oracle>(getOracleAddress(chainId), ORACLE_ABI)
}
