import { BigNumber as EthersBigNumber, FixedNumber } from '@ethersproject/bignumber'
import { formatUnits } from '@ethersproject/units'
import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'config'
import { UnitEther, unitsEther } from 'config/number'

/**
 * 1.5 -> 1500000000000000
 */
export const getDecimalAmount = (amount: BigNumber | string | number, decimals = 18) => {
  return new BigNumber(amount).multipliedBy(BIG_TEN.pow(decimals)).integerValue()
}

/**
 * 1500000000000000 - > 1.5
 */
export const getBalanceAmount = (amount: BigNumber | string | number, decimals = 18) => {
  return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  return getBalanceAmount(balance, decimals).toNumber()
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString('en-US', options)
}

export function delineate(bnStr: string, decimalDigitTake?: number) {
  const parts = bnStr.split('.')
  if (decimalDigitTake && parts[1]) {
    parts[1] = parts[1].substr(0, decimalDigitTake)
  }

  const formatDecimal = parts[1] && Number(parts[1]) && decimalDigitTake !== 0 ? '.' + parts[1] : ''
  return parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + formatDecimal
}

export const getFullDisplayBalance = (balance: BigNumber | string | number, decimals = 0, displayDecimals?: number) => {
  return delineate(getBalanceAmount(balance, decimals).toString(10), displayDecimals)
}

/**
 * Method to format the display of wei given an EthersBigNumber object
 * Note: does NOT round
 */
export const formatBigNumber = (number: EthersBigNumber, displayDecimals = 18, decimals = 18) => {
  const remainder = number.mod(EthersBigNumber.from(10).pow(decimals - displayDecimals))
  return formatUnits(number.sub(remainder), decimals)
}

/**
 * Method to format the display of wei given an EthersBigNumber object with toFixed
 * Note: rounds
 */
export const formatBigNumberToFixed = (number: EthersBigNumber, displayDecimals = 18, decimals = 18) => {
  const formattedString = formatUnits(number, decimals)
  return (+formattedString).toFixed(displayDecimals)
}

/**
 * Formats a FixedNumber like BigNumber
 * i.e. Formats 9763410526137450427.1196 into 9.763 (3 display decimals)
 */
export const formatFixedNumber = (number: FixedNumber, displayDecimals = 18, decimals = 18) => {
  // Remove decimal
  const [leftSide] = number.toString().split('.')
  return formatBigNumber(EthersBigNumber.from(leftSide), displayDecimals, decimals)
}

export const formatLocalisedCompactNumber = (number: number, maximumSignificantDigits = 2): string => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits,
  }).format(number)
}

export const convertUnit = (value: number | string | BigNumber, fromUnit: UnitEther, toUnit: UnitEther): number => {
  if (value !== 0 && !value) return

  const fromExp = unitsEther[fromUnit]
  const toExp = unitsEther[toUnit]

  if (fromExp === undefined || toExp === undefined) {
    throw new Error('Invalid units')
  }

  const exponentDiff = toExp - fromExp

  if (exponentDiff > 0) {
    return new BigNumber(value.toString()).dividedBy(10 ** Math.abs(exponentDiff)).toNumber()
  }
  return new BigNumber(value.toString()).multipliedBy(10 ** Math.abs(exponentDiff)).toNumber()
}

export const displayBalanceEthValue = (_value: BigNumber | string | number): string => {
  const value = new BigNumber(_value)

  if (value.isZero()) {
    return '0'
  }

  const [parts, decimals] = _value.toString().split('.') || []

  const parseNumberDecimals = new BigNumber([0, decimals].join('.'))

  const kweiValue = parseNumberDecimals.multipliedBy(10 ** unitsEther.kwei)
  const mweiValue = parseNumberDecimals.multipliedBy(10 ** unitsEther.mwei)
  const gweiValue = parseNumberDecimals.multipliedBy(10 ** unitsEther.gwei)
  const szaboValue = parseNumberDecimals.multipliedBy(10 ** unitsEther.szabo)
  const finneyValue = parseNumberDecimals.multipliedBy(10 ** unitsEther.finney)

  if (kweiValue.gt(1)) {
    return delineate(value.toString(10), unitsEther.kwei + 1)
  }

  if (mweiValue.gt(1)) {
    return delineate(value.toString(10), unitsEther.mwei + 1)
  }

  if (gweiValue.gt(1)) {
    return delineate(value.toString(10), unitsEther.gwei + 1)
  }

  if (szaboValue.gt(1)) {
    return delineate(value.toString(10), unitsEther.szabo + 1)
  }
  if (finneyValue.gt(1)) {
    return delineate(value.toString(10), unitsEther.finney + 1)
  }

  return delineate(value.toString(10), unitsEther.ether)
}

export const formatLocalAndDisplayBalanceEthValue = (
  _value: BigNumber | string | number,
  maximumSignificantDigits = 6,
  maxToFormatLocal = 1e5,
): string => {
  const value = new BigNumber(_value)
  if (value.gt(maxToFormatLocal)) {
    return formatLocalisedCompactNumber(value.toNumber(), maximumSignificantDigits)
  }
  return displayBalanceEthValue(value)
}
