import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useEffect } from 'react'
import { useMenuContainer } from 'state/app/hooks'
import styled, { DefaultTheme } from 'styled-components'
import theme from 'theme'
import MenuContent from './MenuContent'

const getMenuWidth = ({ theme, $Open }: { theme: DefaultTheme; $Open: boolean }) => {
  return $Open ? `${theme.navigationWidth}px` : '0px'
}

const StyledContainer = styled(Box)<{ $Open: boolean }>`
  width: ${({ theme }) => theme.topbarHeight};
  min-width: ${({ theme }) => theme.topbarHeight};

  ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }

  z-index: ${({ theme }) => theme.zIndices.menu};
`

const StyledInnerContainer = styled(Box)<{ $Open: boolean }>`
  height: 100vh;
  transition: ease ${({ theme }) => theme.transitions.medium};
  position: absolute;
  width: ${({ $Open }) => ($Open ? '100%' : '0px')};
  min-width: ${({ $Open }) => ($Open ? '100%' : '0px')};
  margin-left: ${({ $Open }) => ($Open ? '0px' : '-1px')};
  overflow: ${({ $Open }) => ($Open ? 'unset' : 'hidden')};

  ${({ theme }) => theme.mediaQueries.sm} {
    width: ${getMenuWidth};
    min-width: ${getMenuWidth};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 0px;
    overflow: unset !important;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    position: relative;
    margin-left: 0px;
  }
`

const StyledMenuWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.background};
  width: 100%;
  height: 100%;
`

const StyledMenuContent = styled(Box)`
  flex: 1 1;
  height: 100%;
  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: #2d3137 !important;
      visibility: visible;
    }
  }
`

const MenuContainer: React.FC<BoxProps> = ({ children, zIndex, ...props }) => {
  const { isMenuOpen } = useMenuContainer()

  return (
    <Box position="fixed" width="100%" height="0px" zIndex={theme.zIndices.menu}>
      <StyledContainer $Open={isMenuOpen} zIndex={zIndex}>
        <StyledInnerContainer $Open={isMenuOpen} {...props} zIndex={zIndex}>
          {children}
        </StyledInnerContainer>
      </StyledContainer>
    </Box>
  )
}

const Menu = () => {
  const { isMenuOpen, toggleMenu } = useMenuContainer()
  const { isMd, isSm, isXs, isMobile, isDesktop } = useMatchBreakpoints()
  const isMenuCanHide = isMd || isSm || isXs

  useEffect(() => {
    if (isMenuOpen && isMobile) {
      document.body.classList.add('no-scroll')
      return () => {
        document.body.classList.remove('no-scroll')
      }
    }
  }, [isMenuOpen, isMobile])

  return (
    <>
      <MenuContainer id="menunavigation">
        <StyledMenuWrapper flexDirection="column">
          <StyledMenuContent overflowX="hidden">
            {isMenuOpen && <MenuContent small={!isMenuOpen && !isMenuCanHide} />}
          </StyledMenuContent>
        </StyledMenuWrapper>
      </MenuContainer>
      {isMenuOpen && (
        <Box
          minWidth="100vw"
          minHeight="var(--screen-height)"
          position="fixed"
          onClick={toggleMenu}
          display={['block', 'block', 'block', 'block', 'block', 'none']}
        />
      )}
    </>
  )
}

export default Menu
