import { urlRoute } from './route'

export enum LinkTypeEnum {
  INTERNAL_LINK = 0,
  EXTERNAL_LINK = 1,
}

export const navigationItems = [
  {
    label: 'Earn',
    href: urlRoute.pools().to,
  },
  {
    label: 'Strategies',
    href: urlRoute.strategies().to,
  },
  {
    label: 'Portfolio',
    href: urlRoute.portfolio().to,
  },
]

export const socials = [
  {
    label: 'Docs',
    href: 'https://gentoo-finance.gitbook.io/gentoo-finance/',
  },
  {
    label: 'Blog',
    href: 'https://substack.com/@gentoofinance',
  },
  {
    label: 'Telegram',
    href: 'https://t.me/gentoofinance',
  },
  {
    label: 'Twitter',
    href: 'https://twitter.com/Gentoofinance',
  },
]

export type NavigationItem = {
  label: string
  href: string
  type?: LinkTypeEnum
  items?: {
    label: string
    href: string
  }[]
}
