import { ConnectorNotFoundError } from '@wagmi/core'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button/Button'
import ButtonGroupItem from 'components/Button/ButtonGroupItem'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import Link from 'components/Link/Link'
import LinkExternal from 'components/Link/LinkExternal'
import CircleLoader from 'components/Loader/CircleLoader'
import Dots from 'components/Loader/Dots'
import { ModalBody, ModalProps } from 'components/Modal'
import Text from 'components/Text/Text'
import useAuth from 'hooks/useAuth'
import Column, { ColumnCenter } from 'layout/components/Column'
import { RowBetween, RowCenter } from 'layout/components/Row'
import { ConnectorNames } from 'packages/wagmi/wallet'
import { PropsWithChildren, Suspense, lazy, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelectedWallet } from 'state/app/hooks'
import { MoreHorizontalIcon, WarningIcon } from 'svgs'
import theme from 'theme'
import {
  DesktopWalletSelectionClass,
  PromotedGradientClass,
  StyledWrapButtonGroupItem,
  WalletSelectWrapperClass,
  WrapperModalWallet,
  walletIconClass,
} from './WalletModal.css'
import {
  LinkOfDevice,
  WalletConfig,
  WalletConnectorNotFoundError,
  WalletModalProps,
  WalletSwitchChainError,
} from './types'
import { CHAIN_MAINNET } from 'config/networks'

const StepIntro = lazy(() => import('./components/Intro'))
const Qrcode = lazy(() => import('./components/QRCode'))

const MOBILE_DEFAULT_DISPLAY_COUNT = 8

const TabContainer = ({ children, docLink, docText }: PropsWithChildren<{ docLink: string; docText: string }>) => {
  const [index, setIndex] = useState(0)
  const activeAtFirstChild = index === 0

  return (
    <Box height="100%" position="relative">
      <Box position="absolute" top={['-46px', '', '-54px']} left={['-12px', '', '-20px']}>
        <StyledWrapButtonGroupItem mb="24px">
          <ButtonGroupItem
            style={{
              background: activeAtFirstChild ? theme.colors.input : theme.colors.modalBackground,
            }}
            $active={activeAtFirstChild}
            onClick={() => setIndex(0)}
          >
            Connect Wallet
          </ButtonGroupItem>
          <ButtonGroupItem
            style={{
              background: !activeAtFirstChild ? theme.colors.input : theme.colors.modalBackground,
            }}
            $active={!activeAtFirstChild}
            onClick={() => setIndex(1)}
          >
            What’s a Web3 Wallet?
          </ButtonGroupItem>
        </StyledWrapButtonGroupItem>
      </Box>
      <Box display="flex" position="relative" height="100%" overflow="hidden" zIndex="modal" width="100%">
        {index === 0 && children}
        {index === 1 && (
          <Suspense>
            <StepIntro docLink={docLink} docText={docText} />
          </Suspense>
        )}
      </Box>
    </Box>
  )
}

const MobileModal = <T extends any>({
  wallets,
  connectWallet,
  docLink,
  docText,
  error,
}: Pick<WalletModalProps<T>, 'wallets' | 'docLink' | 'docText'> & {
  connectWallet: (wallet: WalletConfig<T>) => void
  error: string
}) => {
  const [selected] = useSelectedWallet()

  const installedWallets: WalletConfig<T>[] = wallets.filter((w) => w.installed)
  const walletsToShow: WalletConfig<T>[] = wallets.filter((w) => {
    if (installedWallets.length) {
      return w.installed
    }
    return w.installed !== false || w.deepLink
  })

  return (
    <Column width="100%">
      {error ? (
        <ColumnCenter my="24px" p={['0', '', '24px']}>
          {selected && typeof selected.icon === 'string' && (
            <Image
              src={selected.icon}
              width={80}
              height={80}
              style={{
                marginBottom: '12px',
              }}
            />
          )}
          <Box style={{ maxWidth: '246px' }}>
            <ErrorMessage message={error} />
          </Box>
        </ColumnCenter>
      ) : (
        <Text color="textSubtle" small p={['4px 24px 0 0', '', '8px 24px 24px']}>
          Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrase securely.
          Never share them with anyone.
        </Text>
      )}
      <Flex flex={1} py="16px" style={{ maxHeight: '230px' }}>
        <WalletSelect
          displayCount={MOBILE_DEFAULT_DISPLAY_COUNT}
          wallets={walletsToShow}
          onClick={(wallet) => {
            connectWallet(wallet)
            if (wallet.deepLink && wallet.installed === false) {
              window.open(wallet.deepLink)
            }
          }}
        />
      </Flex>
      <ColumnCenter p={['24px 0 0', '', '24px']} borderTop={`1px solid ${theme.colors.textSubtle}`} mt="auto">
        <Text textAlign="center" color="textSubtle" as="p">
          Haven’t got a crypto wallet yet?
        </Text>
        <Button
          hoverUnderLine
          as={LinkExternal}
          hideIcon
          color="backgroundAlt"
          variant="text"
          href={docLink}
          width="100%"
        >
          {docText}
        </Button>
      </ColumnCenter>
    </Column>
  )
}

const WalletSelect = <T extends any>({
  wallets,
  onClick,
  displayCount = 9,
}: {
  wallets: WalletConfig<T>[]
  onClick: (wallet: WalletConfig<T>) => void
  displayCount?: number
}) => {
  const [showMore, setShowMore] = useState(false)
  const walletDisplayCount = wallets.length > displayCount ? displayCount - 1 : displayCount
  const walletsToShow = showMore ? wallets : wallets.slice(0, walletDisplayCount)
  const [selected] = useSelectedWallet()

  return (
    <WalletSelectWrapperClass
      display="grid"
      overflowY="auto"
      overflowX="hidden"
      px={['0', '', '24px']}
      pb="12px"
      className={WalletSelectWrapperClass}
    >
      {walletsToShow.map((wallet) => {
        const isImage = typeof wallet.icon === 'string'
        const Icon = wallet.icon

        return (
          <Button
            key={wallet.id}
            variant="text"
            height="auto"
            as={Box}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              letterSpacing: 'normal',
              flexDirection: 'column',
              padding: '0',
            }}
            onClick={() => onClick(wallet)}
          >
            <PromotedGradientClass $installed={wallet.installed} p="2px" borderRadius="12px" mb="4px">
              <RowCenter
                style={{
                  background: theme.colors.textTertiary,
                  borderRadius: '13px',
                }}
                position="relative"
                className={walletIconClass}
                minWidth={'50px'}
                minHeight={'50px'}
              >
                {isImage ? (
                  <Image src={Icon as string} width={52} height={52} />
                ) : (
                  <Icon
                    width={32}
                    height={32}
                    style={{
                      stroke: theme.colors.textSubtle,
                    }}
                  />
                )}
                {wallet.id === selected?.id && (
                  <Box
                    width="100%"
                    height="100%"
                    position="absolute"
                    background="#7645d9"
                    style={{ opacity: 0.5 }}
                    borderRadius="12px"
                  />
                )}
              </RowCenter>
            </PromotedGradientClass>
            <Text fontSize="12px" textAlign="center">
              {wallet.title}
            </Text>
          </Button>
        )
      })}
      {!showMore && wallets.length > walletDisplayCount && (
        <RowCenter flexDirection="column">
          <Button height="auto" variant="text" as={Box} flexDirection="column" onClick={() => setShowMore(true)}>
            <Column>
              <RowCenter
                minWidth="50px"
                minHeight="50px"
                mb="4px"
                className={walletIconClass}
                style={{
                  background: theme.colors.textTertiary,
                  borderRadius: '12px',
                }}
              >
                <MoreHorizontalIcon
                  style={{
                    stroke: theme.colors.modalBackground,
                    fill: theme.colors.modalBackground,
                  }}
                />
              </RowCenter>
              <Text fontSize="12px" textAlign="center">
                More
              </Text>
            </Column>
          </Button>
        </RowCenter>
      )}
    </WalletSelectWrapperClass>
  )
}

const sortWallets = <T extends any>(wallets: WalletConfig<T>[], lastUsedWalletName: string | null) => {
  const sorted = [...wallets].sort((a, b) => {
    if (a.installed === b.installed) return 0
    return a.installed === true ? -1 : 1
  })

  if (!lastUsedWalletName) {
    return sorted
  }
  const foundLastUsedWallet = wallets.find((w) => w.title === lastUsedWalletName)
  if (!foundLastUsedWallet) return sorted
  return [foundLastUsedWallet, ...sorted.filter((w) => w.id !== foundLastUsedWallet.id)]
}

const DesktopModal = <T extends any>({
  wallets: wallets_,
  connectWallet,
  docLink,
  docText,
  error,
}: Pick<WalletModalProps<T>, 'wallets' | 'docLink' | 'docText'> & {
  connectWallet: (wallet: WalletConfig<T>) => void
  error: string
}) => {
  const wallets: WalletConfig<T>[] = wallets_.filter((w) => {
    return w.installed !== false || (!w.installed && (w.guide || w.downloadLink || w.qrCode))
  })

  const [selected] = useSelectedWallet<T>()
  const [qrCode, setQrCode] = useState<string | undefined | false>(undefined)

  const connectToWallet = (wallet: WalletConfig<T>) => {
    connectWallet(wallet)
  }

  return (
    <>
      <DesktopWalletSelectionClass width="100%">
        <Box px={['0', '', '', '24px']}>
          <Heading color="text" as="h4">
            Connect Wallet
          </Heading>
          <Text color="textSubtle" lineHeight={1.5} pt="8px" pb="24px">
            Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrase
            securely. Never share them with anyone.
          </Text>
        </Box>
        <WalletSelect
          wallets={wallets}
          onClick={(w) => {
            connectToWallet(w)
            setQrCode(false)
            if (w.qrCode) {
              w.qrCode().then(
                (uri) => {
                  setQrCode(uri)
                },
                (errr) => {
                  setQrCode(undefined)
                },
              )
            } else {
              setQrCode(undefined)
            }
          }}
        />
      </DesktopWalletSelectionClass>
      <Flex
        width="100%"
        maxWidth={['280px', '', '', '300px']}
        minWidth="200px"
        ml="24px"
        display={['none !important', '', 'flex !important']}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <ColumnCenter style={{ gap: '24px', justifyContent: 'center' }}>
          {!selected && <Intro docLink={docLink} docText={docText} />}
          {selected && selected.installed !== false && (
            <>
              {typeof selected.icon === 'string' && <Image src={selected.icon} width={108} height={108} />}
              <Heading as="h1" fontSize="20px" color="secondary">
                Opening {selected.title}
              </Heading>
              {error ? (
                <ErrorContent message={error} onRetry={() => connectToWallet(selected)} />
              ) : (
                <Dots>Please confirm in {selected.title}</Dots>
              )}
            </>
          )}
          {selected && selected.installed === false && <NotInstalled qrCode={qrCode} wallet={selected} />}
        </ColumnCenter>
      </Flex>
    </>
  )
}

const Intro = ({ docLink, docText }: { docLink: string; docText: string }) => {
  return (
    <>
      <Heading as="h1" fontSize="20px" color="secondary">
        Haven’t got a crypto wallet yet?
      </Heading>
      <Image src="https://cdn.pancakeswap.com/wallets/wallet_intro.png" width={198} height={178} />
      <Button
        hoverUnderLine
        as={LinkExternal}
        hideIcon
        style={{
          color: theme.colors.primaryBright,
        }}
        variant="text"
        href={docLink}
      >
        {docText}
      </Button>
    </>
  )
}

const NotInstalled = ({ wallet, qrCode }: { wallet: WalletConfig; qrCode?: string | false }) => {
  return (
    <>
      <Heading as="h1" fontSize="20px" color="secondary">
        {wallet.title} is not installed
      </Heading>

      {qrCode === false ? (
        <RowCenter minHeight="250px" width="250px">
          <CircleLoader size="30px" />
        </RowCenter>
      ) : qrCode === undefined ? (
        <Text maxWidth="246px" m="auto" textAlign="center">
          Please install the {wallet.title} browser extension to connect the {wallet.title} wallet.
        </Text>
      ) : (
        <Suspense>
          <Box overflow="hidden" borderRadius={theme.radii.default} style={{ width: '250px', height: '250px' }}>
            <Qrcode url={qrCode} image={typeof wallet.icon === 'string' ? wallet.icon : undefined} />
          </Box>
        </Suspense>
      )}

      <RowBetween width="100%" mt="24px" maxWidth="250px">
        {wallet.guide && (
          <Link
            mx={!wallet.downloadLink && 'auto'}
            style={{
              textDecoration: 'underline',
            }}
            href={getDesktopLink(wallet.guide)}
            external
          >
            {getDesktopText(wallet.guide, 'Setup Guide')}
          </Link>
        )}
        {wallet.downloadLink && (
          <Link
            mx={!wallet.guide && 'auto'}
            style={{
              textDecoration: 'underline',
            }}
            href={getDesktopLink(wallet.downloadLink)}
            external
          >
            {getDesktopText(wallet.downloadLink, 'Install')}
          </Link>
        )}
      </RowBetween>
    </>
  )
}

const ErrorMessage = ({ message }: { message: string }) => (
  <Text
    bold
    color="failure"
    display="inline-block"
    style={{ alignItems: 'flex-start', justifyContent: 'center', textAlign: 'center' }}
  >
    <WarningIcon width="24px" style={{ verticalAlign: 'middle', fill: theme.colors.failure, marginRight: '4px' }} />{' '}
    {message}
  </Text>
)

const ErrorContent = ({ onRetry, message }: { onRetry: () => void; message: string }) => {
  return (
    <>
      <ErrorMessage message={message} />
      <Button onClick={onRetry}>Retry</Button>
    </>
  )
}

const getDesktopLink = (linkDevice: LinkOfDevice) =>
  typeof linkDevice === 'string'
    ? linkDevice
    : typeof linkDevice.desktop === 'string'
    ? linkDevice.desktop
    : linkDevice.desktop?.url

const getDesktopText = (linkDevice: LinkOfDevice, fallback: string) =>
  typeof linkDevice === 'string'
    ? fallback
    : typeof linkDevice.desktop === 'string'
    ? fallback
    : linkDevice.desktop?.text ?? fallback

const WalletModal = ({ onDismiss }: ModalProps) => {
  const docText = 'Learn How to Connect'
  const docLink = 'https://docs.pancakeswap.finance/get-started/wallet-guide'

  const { login } = useAuth()

  const [selected, setSelected, _wallets] = useSelectedWallet<ConnectorNames>()
  const wallets = sortWallets<ConnectorNames>(_wallets, selected?.title)
  const [error, setError] = useState('')

  const connectWallet = (wallet: WalletConfig<ConnectorNames>) => {
    setSelected(wallet.id)
    setError('')

    if (wallet.installed !== false) {
      login(wallet.connectorId, CHAIN_MAINNET)
        .then((v) => {
          if (v) {
            onDismiss()
          }
        })
        .catch((err) => {
          if (err instanceof ConnectorNotFoundError || err instanceof WalletConnectorNotFoundError) {
            setError('Wallet is not found!')
          } else if (err instanceof WalletSwitchChainError) {
            setError(err.message)
          } else {
            setError('Error connecting, please authorize wallet to access.')
          }
        })
    }
  }

  return (
    <WrapperModalWallet onDismiss={onDismiss}>
      <ModalBody height="100%" style={{ overflow: 'visible', border: 'none', position: 'relative' }}>
        <TabContainer docLink={docLink} docText={docText}>
          {isMobile ? (
            <MobileModal
              error={error}
              connectWallet={connectWallet}
              wallets={wallets}
              docLink={docLink}
              docText={docText}
            />
          ) : (
            <DesktopModal
              error={error}
              connectWallet={connectWallet}
              wallets={wallets}
              docLink={docLink}
              docText={docText}
            />
          )}
        </TabContainer>
      </ModalBody>
    </WrapperModalWallet>
  )
}

export default WalletModal
