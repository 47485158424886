import { createReducer } from '@reduxjs/toolkit'
import { ChainIdEnum, CHAIN_MAINNET } from 'config/networks'
import { changeNetwork, setMenuOpen, setSelectedWallet } from './actions'

export interface AppState {
  network: ChainIdEnum
  isMenuOpen: boolean
  walletId: string
}

const initialState: AppState = {
  network: CHAIN_MAINNET,
  isMenuOpen: false,
  walletId: '',
}

const appReducer = createReducer<AppState>(initialState, (builder) =>
  builder
    .addCase(changeNetwork, (state, { payload }) => {
      const { network } = payload
      state.network = network

      return state
    })
    .addCase(setMenuOpen, (state, { payload }) => {
      const { isOpen } = payload
      state.isMenuOpen = isOpen
      return state
    })
    .addCase(setSelectedWallet, (state, action) => {
      state.walletId = action.payload.walletId
    }),
)

export default appReducer
