import { createAction } from '@reduxjs/toolkit'
import {
  IAddLiquidityPortfolio,
  IBoxCreditedDepositPortfolio,
  IBoxCreditedLiquidatePortfolio,
  IBoxCreditedWithdrawPortfolio,
  IRemoveLiquidityPortfolio,
} from './types'

export const updateActionsPortfolio = createAction<{
  addLiquidities: IAddLiquidityPortfolio[]
  removeLiquidities: IRemoveLiquidityPortfolio[]
  boxCreditedDeposits: IBoxCreditedDepositPortfolio[]
  boxCreditedWithdraws: IBoxCreditedWithdrawPortfolio[]
  boxCreditedLiquidates: IBoxCreditedLiquidatePortfolio[]
}>('portfolio/updateActionsPortfolio')
export const resetActionsPortfolio = createAction('portfolio/resetActionsPortfolio')

export const updateSearchAccountPortfolio = createAction<string>('portfolio/updateSearchAccountPortfolio')
