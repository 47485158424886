import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import MenuDropdown from 'components/Dropdown'
import useAuth from 'hooks/useAuth'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import CopyIcon from 'svgs/copy.svg'
import DropdownIcon from 'svgs/dropdown.svg'
import LinkExternalIcon from 'svgs/external-link.svg'
import SignoutIcon from 'svgs/signout.svg'

import IconStatus from 'components/IconStatus'
import CircleLoader from 'components/Loader/CircleLoader'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useUsername from 'hooks/useUsername'
import { RowCenter } from 'layout/components/Row'
import { copyToClipboardWithCommand, getExplorerLink, getNameExplorer } from 'utils'
import getExternalLinkProps from 'utils/getExternalLinkProps'
import Text from '../Text'
import { Position } from 'components/Popper/typpes'

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  font-size: 12px;
  svg {
    width: 14px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 14px;
    svg {
      width: 24px;
    }
  }
`

const StyledDropdown = styled(MenuDropdown)`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.gradients.button};
  padding: 8px;
  height: 38px;
`

const StyledFunctionContainer = styled(Flex)`
  transition: ${({ theme }) => theme.transitions.fast};
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
    margin-right: 8px;
  }

  :hover {
    background: ${({ theme }) => theme.colors.hover};

    ${Text} {
      color: ${({ theme }) => theme.colors.text};
    }

    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`

const StyledStroke = styled.div`
  margin: 12px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.disabled};
`

const AccountDropdown: React.FC<
  BoxProps & {
    dropdownPosition?: Position
    offsetBottom?: number
  }
> = ({ dropdownPosition = 'bottom-right', offsetBottom, ...props }) => {
  const { account, chainId } = useActiveWeb3React()
  const { logout } = useAuth()

  const { username } = useUsername(account)
  const onModalInfoWallet = () => {}
  const [copyAddress, setCopyAddress] = useState(false)

  const handleCopyAddress = useCallback(() => {
    copyToClipboardWithCommand(account, () => {
      setCopyAddress(true)
      setTimeout(() => {
        setCopyAddress(false)
      }, 1000)
    })
  }, [account])

  const DropdownContent = useMemo(() => {
    return (
      <Flex p="8px 0px" flexDirection="column" minWidth="228px">
        <StyledFunctionContainer p="10px 18px" alignItems="center" onClick={handleCopyAddress}>
          <CopyIcon width="18px" />
          <Text color="textSubtle" mr="16px">
            Copy address
          </Text>
          <Box height="20px">{copyAddress && <IconStatus status="success" />}</Box>
        </StyledFunctionContainer>

        <StyledFunctionContainer p="10px 18px" alignItems="center" onClick={logout}>
          <SignoutIcon width="18px" />
          <Text color="textSubtle">Sign out</Text>
        </StyledFunctionContainer>
        <StyledStroke />
        <StyledFunctionContainer
          p="10px 18px"
          alignItems="center"
          as="a"
          {...getExternalLinkProps()}
          href={getExplorerLink(account, 'address', chainId)}
        >
          <Text color="textSubtle">View on {getNameExplorer(chainId)}</Text>
          <LinkExternalIcon style={{ marginLeft: 8 }} />
        </StyledFunctionContainer>
      </Flex>
    )
  }, [account, chainId, copyAddress, handleCopyAddress, logout, onModalInfoWallet])

  return (
    <StyledDropdown
      offsetBottom={offsetBottom}
      dropdownPosition={dropdownPosition}
      dropdownContent={DropdownContent}
      {...props}
    >
      <StyledContainer pl="6px" minWidth={['50px', '', '100px']}>
        {username ? (
          <>
            {username} <DropdownIcon fill="#fff" style={{ marginLeft: 4 }} />
          </>
        ) : (
          <RowCenter>
            <CircleLoader mx="auto" stroke="#fff" />
          </RowCenter>
        )}
      </StyledContainer>
    </StyledDropdown>
  )
}

export default React.memo(AccountDropdown)
