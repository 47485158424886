import { LIQUIDITY_POOL_ABI, ORACLE_ABI } from 'config/abi'
import { ChainIdEnum } from 'config/networks'
import { Pool } from 'config/types/pool'
import chunk from 'lodash/chunk'
import { getLiquidityPoolAddress } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'

const fetchPoolCalls = (pool: Pool, chainId: ChainIdEnum) => {
  const { token } = pool
  return [
    {
      address: getLiquidityPoolAddress(chainId),
      name: 'getBearingRate',
      params: [token.address],
    },
    {
      address: getLiquidityPoolAddress(chainId),
      name: 'assets',
      params: [token.address],
    },
  ]
}

export const fetchPublicPoolsData = async (pools: Pool[], chainId: ChainIdEnum): Promise<any[]> => {
  const poolCalls = pools.flatMap((pool) => fetchPoolCalls(pool, chainId))
  const chunkSize = poolCalls.length / pools.length

  const poolMultiCallResult = await multicallv2([...LIQUIDITY_POOL_ABI, ...ORACLE_ABI], poolCalls, chainId)

  return chunk(poolMultiCallResult, chunkSize)
}
