import { useEffect, useState } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import SID, { getSidAddress } from '@siddomains/sidjs'
import useProvider from './useProvider'
import { ChainIdEnum } from 'config/networks'

export const useSID = () => {
  const [sid, setSid] = useState(null)
  const provider = useProvider(ChainIdEnum.BSC)

  const initiSid = () => {
    const init = new SID({ provider, sidAddress: getSidAddress(provider.network.chainId) })
    setSid(init)
  }

  useEffect(() => {
    if (provider) {
      initiSid()
    }
  }, [provider])

  return sid
}
