import { APIEnum } from '../ApiEnum'
import { BoxInfoMapper } from './BoxMapper'

class Mapper {
  private _mapper = {
    [APIEnum.boxInfo]: BoxInfoMapper,
  }

  public getMapper(url: string) {
    return (this._mapper as any)[url]
  }
}

const mapper = new Mapper()

export default mapper
