import { BaseResponse, BoxInfoResponse } from '../types'

export const BoxInfoMapper = (rawResponse: string): BaseResponse<BoxInfoResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    error: response.error,
    data:
      response.data &&
      response.data.map((item) => ({
        name: item.name,
        strategy: item.strategy,
        address: item.address,
        stakingToken: {
          address: item.address,
          coingeckoId: item.coingeckoId,
        },
        yieldToken: {
          address: item.address,
          coingeckoId: item.coingeckoId,
        },
        ankrMetricId: item.ankrMetricId,
        wombatAsset: item.wombatAsset,
        magpieReceipt: item.magpieReceipt,
        apyTotal: item.apyTotal,
        apyDetails: {
          ankr: item.ankr,
          wombat: item.wombat,
          magpie: item.magpie,
        },
      })),
  }
}
