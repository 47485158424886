import { createGlobalStyle } from 'styled-components'
import { AppTheme } from 'theme/types'

declare module 'styled-components' {
  export interface DefaultTheme extends AppTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Rubik', sans-serif;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  }

  html{
    background: ${({ theme }) => theme.colors.background};
  }

  -webkit-tap-highlight-color: transparent;
  
  body {
    min-height: 100vh;
    background: linear-gradient(118deg, rgb(17, 21, 35) 0%, rgb(47, 59, 90) 100%);
    overflow: overlay;
    
    img {
      height: auto;
      max-width: 100%;
    }
    
    ::-webkit-scrollbar-track {
      width: 0px;
    }
    ::-webkit-scrollbar {
      width: 0px;
    }
    
    &.no-scroll {
      overflow: hidden;
    }
  }
`

export default GlobalStyle
