import ArrowLeftIcon from 'svgs/arrow-left.svg'
import ArrowDownIcon from 'svgs/arrow-down.svg'
import SettingIcon from 'svgs/setting.svg'
import CloseIcon from 'svgs/close.svg'
import DropdownIcon from 'svgs/dropdown.svg'
import BackForwardIcon from 'svgs/back.svg'
import CalculateIcon from 'svgs/calculator.svg'
import BetweenYieldIcon from 'svgs/between-yield.svg'
import DeltaNeutralIcon from 'svgs/delta-neutral.svg'
import OrganicLiquidIcon from 'svgs/organic-liquid.svg'
import FireIcon from 'svgs/fire.svg'
import MenuBarIcon from 'svgs/menubar.svg'

import PassiveSideImage from 'svgs/passive-side.svg'
import LeverageSideImage from 'svgs/leverage-site.svg'
import IntegrationImage from 'svgs/integration.svg'
import IntegrationMobileImage from 'svgs/integration-mobile.svg'
import OpenNewIcon from 'svgs/external-link.svg'

import PancakeSwapIcon from 'svgs/pancakeswap.svg'
import VenusIcon from 'svgs/venus.svg'
import SushiIcon from 'svgs/sushi.svg'
import BiswapIcon from 'svgs/biswap.svg'
import HelioIcon from 'svgs/helio.svg'

import WombatIcon from 'svgs/wombat.svg'
import AnkrIcon from 'svgs/ankr.svg'
import MagpieIcon from 'svgs/magpie.svg'

import HelpIcon from './help.svg'
import ExternalIcon from './external.svg'
import MoreHorizontalIcon from './more-horizontal.svg'
import WarningIcon from './warning.svg'
import WalletFilledIcon from './wallet-fill.svg'
import MetamaskIcon from './metamask.svg'
import SpaceIDIcon from './space-id.svg'

export {
  ArrowLeftIcon,
  ArrowDownIcon,
  SettingIcon,
  CloseIcon,
  FireIcon,
  DropdownIcon,
  BackForwardIcon,
  CalculateIcon,
  BetweenYieldIcon,
  DeltaNeutralIcon,
  OrganicLiquidIcon,
  PassiveSideImage,
  LeverageSideImage,
  IntegrationImage,
  IntegrationMobileImage,
  PancakeSwapIcon,
  VenusIcon,
  SushiIcon,
  BiswapIcon,
  HelioIcon,
  WombatIcon,
  AnkrIcon,
  MagpieIcon,
  MenuBarIcon,
  HelpIcon,
  OpenNewIcon,
  ExternalIcon,
  MoreHorizontalIcon,
  WarningIcon,
  WalletFilledIcon,
  MetamaskIcon,
  SpaceIDIcon,
}
