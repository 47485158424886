import addresses, { AddressNames } from 'config/addressBook'
import { ChainIdEnum } from 'config/networks'

export const compareStringValue = (addressA: string, addressB: string): boolean =>
  addressA && addressB && addressA.toString().toLowerCase() === addressB.toString().toLowerCase()

export const getAddress = (addressName: keyof AddressNames, chainId: number): string => {
  return addresses[chainId] ? addresses[chainId][addressName] : null
}

export const getMulticallAddress = (chainId: ChainIdEnum) => {
  return getAddress('MULTICALL', chainId)
}

export const getOracleAddress = (chainId: ChainIdEnum) => {
  return getAddress('ORACLE', chainId)
}

export const getLiquidityPoolAddress = (chainId: ChainIdEnum) => {
  return getAddress('LIQUIDITY_POOL', chainId)
}

export const getWethGateway = (chainId: ChainIdEnum) => {
  return getAddress('WETH_GATEWAY', chainId)
}
