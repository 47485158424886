import { BitNetwork, CreateInstanceConfig, createInstance } from 'dotbit'
import { useMemo } from 'react'

export const useBid = (config?: CreateInstanceConfig) => {
  const dotbit = useMemo(
    () =>
      createInstance({
        network: BitNetwork.mainnet,
        ...(config || {}),
      }),
    [config],
  )

  return dotbit
}
