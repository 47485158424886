import React from 'react'
import Text from 'components/Text'
import Link from 'components/Link'
import { getExplorerLink } from 'utils'
import truncateHash from 'utils/truncateHash'
import { ChainIdEnum, listNetwork } from 'config/networks'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
  title?: string
  href?: string
  chainId?: ChainIdEnum
  children?: React.ReactNode
}

const DescriptionWithTx: React.FC<DescriptionWithTxProps> = ({ txHash, children, title, href, chainId }) => {
  return (
    <>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link external href={href || getExplorerLink(txHash, 'transaction', chainId)}>
          {title || `View on ${listNetwork[chainId].info.blockExplorers.default.name}`}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx
