import { ZERO_ADDRESS } from 'config'
import addresses from './addressBook'
import { ChainIdEnum } from './networks'

// ADD NETWORK
export const TOKENS = {
  [ChainIdEnum.SEPOLIA]: {
    ETH: {
      address: ZERO_ADDRESS,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: true,
      name: 'Ethereum',
      symbol: 'ETH',
      projectLink: '',
      tokenLogo: '/images/tokens/ETH.png',
    },
    WETH: {
      address: addresses[ChainIdEnum.SEPOLIA].WBNB,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: false,
      name: 'WETH',
      symbol: 'WETH',
      projectLink: '',
      tokenLogo: '/images/tokens/ETH.png',
    },
    USDC: {
      address: addresses[ChainIdEnum.SEPOLIA].USDC,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: false,
      name: 'USDC',
      symbol: 'USDC',
      projectLink: '',
      tokenLogo: '/images/tokens/USDC.png',
    },

    BUSD: {
      address: addresses[ChainIdEnum.SEPOLIA].BUSD,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: false,
      name: 'BUSD',
      symbol: 'BUSD',
      projectLink: '',
      tokenLogo: '/images/tokens/BUSD.png',
    },

    USDT: {
      address: addresses[ChainIdEnum.SEPOLIA].USDT,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: false,
      name: 'USDT',
      symbol: 'USDT',
      projectLink: '',
      tokenLogo: '/images/tokens/USDT.png',
    },

    brUSDC: {
      address: addresses[ChainIdEnum.SEPOLIA].brUSDC,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: false,
      name: 'Bearing USDC',
      symbol: 'brUSDC',
      projectLink: '',
      tokenLogo: '/images/tokens/USDC.png',
    },
    brUSDT: {
      address: addresses[ChainIdEnum.SEPOLIA].brUSDT,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: false,
      name: 'Bearing USDT',
      symbol: 'brUSDT',
      projectLink: '',
      tokenLogo: '/images/tokens/USDT.png',
    },
    brBUSD: {
      address: addresses[ChainIdEnum.SEPOLIA].brBUSD,
      chainId: ChainIdEnum.SEPOLIA,
      decimals: 18,
      isNative: false,
      name: 'Bearing BUSD',
      symbol: 'brBUSD',
      projectLink: '',
      tokenLogo: '/images/tokens/BUSD.png',
    },
  },

  [ChainIdEnum.BSC_TESTNET]: {
    BNB: {
      address: ZERO_ADDRESS,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: true,
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      projectLink: '',
      tokenLogo: '/images/tokens/BNB.png',
    },
    WBNB: {
      address: addresses[ChainIdEnum.BSC_TESTNET].WBNB,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'WBNB',
      symbol: 'WBNB',
      projectLink: '',
      tokenLogo: '/images/tokens/BNB.png',
    },
    ETH: {
      address: addresses[ChainIdEnum.BSC_TESTNET].ETH,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'ETH',
      symbol: 'ETH',
      projectLink: '',
      tokenLogo: '/images/tokens/ETH.png',
    },

    USDC: {
      address: addresses[ChainIdEnum.BSC_TESTNET].USDC,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'USDC',
      symbol: 'USDC',
      projectLink: '',
      tokenLogo: '/images/tokens/USDC.png',
    },

    BUSD: {
      address: addresses[ChainIdEnum.BSC_TESTNET].BUSD,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'BUSD',
      symbol: 'BUSD',
      projectLink: '',
      tokenLogo: '/images/tokens/BUSD.png',
    },

    USDT: {
      address: addresses[ChainIdEnum.BSC_TESTNET].USDT,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'USDT',
      symbol: 'USDT',
      projectLink: '',
      tokenLogo: '/images/tokens/USDT.png',
    },

    VAI: {
      address: addresses[ChainIdEnum.BSC_TESTNET].VAI,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'VAI',
      symbol: 'VAI',
      projectLink: '',
      tokenLogo: '/images/tokens/VAI.png',
    },

    XVS: {
      address: addresses[ChainIdEnum.BSC_TESTNET].XVS,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'XVS',
      symbol: 'XVS',
      projectLink: '',
      tokenLogo: '/images/tokens/XVS.png',
    },

    BSW: {
      address: addresses[ChainIdEnum.BSC_TESTNET].BSW,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'BSW',
      symbol: 'BSW',
      projectLink: '',
      tokenLogo: '/images/tokens/BSW.png',
    },

    brUSDC: {
      address: addresses[ChainIdEnum.BSC_TESTNET].brUSDC,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'Bearing USDC',
      symbol: 'brUSDC',
      projectLink: '',
      tokenLogo: '/images/tokens/USDC.png',
    },
    brUSDT: {
      address: addresses[ChainIdEnum.BSC_TESTNET].brUSDT,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'Bearing USDT',
      symbol: 'brUSDT',
      projectLink: '',
      tokenLogo: '/images/tokens/USDT.png',
    },
    brBUSD: {
      address: addresses[ChainIdEnum.BSC_TESTNET].brBUSD,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'Bearing BUSD',
      symbol: 'brBUSD',
      projectLink: '',
      tokenLogo: '/images/tokens/BUSD.png',
    },

    ankrBNB: {
      address: addresses[ChainIdEnum.BSC_TESTNET].ankrBNB,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'ankrBNB',
      symbol: 'ankrBNB',
      tokenLogo: '/images/tokens/ankrBNB.png',
    },
    ankrETH: {
      address: addresses[ChainIdEnum.BSC_TESTNET].ankrETH,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'ankrETH',
      symbol: 'ankrETH',
      tokenLogo: '/images/tokens/ankrETH.png',
    },

    WOM: {
      address: addresses[ChainIdEnum.BSC_TESTNET].WOM,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'WOM',
      symbol: 'WOM',
      tokenLogo: '/images/tokens/WOM.png',
    },
    MGP: {
      address: addresses[ChainIdEnum.BSC_TESTNET].MGP,
      chainId: ChainIdEnum.BSC_TESTNET,
      decimals: 18,
      isNative: false,
      name: 'MGP',
      symbol: 'MGP',
      tokenLogo: '/images/tokens/MGP.png',
    },
  },

  [ChainIdEnum.BSC]: {
    BNB: {
      address: ZERO_ADDRESS,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: true,
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      projectLink: '',
      tokenLogo: '/images/tokens/BNB.png',
    },
    WBNB: {
      address: addresses[ChainIdEnum.BSC].WBNB,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'WBNB',
      symbol: 'WBNB',
      projectLink: '',
      tokenLogo: '/images/tokens/BNB.png',
    },
    ETH: {
      address: addresses[ChainIdEnum.BSC].ETH,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'ETH',
      symbol: 'ETH',
      projectLink: '',
      tokenLogo: '/images/tokens/ETH.png',
    },

    USDC: {
      address: addresses[ChainIdEnum.BSC].USDC,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'USDC',
      symbol: 'USDC',
      projectLink: '',
      tokenLogo: '/images/tokens/USDC.png',
    },

    BUSD: {
      address: addresses[ChainIdEnum.BSC].BUSD,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'BUSD',
      symbol: 'BUSD',
      projectLink: '',
      tokenLogo: '/images/tokens/BUSD.png',
    },

    USDT: {
      address: addresses[ChainIdEnum.BSC].USDT,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'USDT',
      symbol: 'USDT',
      projectLink: '',
      tokenLogo: '/images/tokens/USDT.png',
    },

    brUSDC: {
      address: addresses[ChainIdEnum.BSC].brUSDC,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'Bearing USDC',
      symbol: 'brUSDC',
      projectLink: '',
      tokenLogo: '/images/tokens/USDC.png',
    },
    brUSDT: {
      address: addresses[ChainIdEnum.BSC].brUSDT,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'Bearing USDT',
      symbol: 'brUSDT',
      projectLink: '',
      tokenLogo: '/images/tokens/USDT.png',
    },
    brBUSD: {
      address: addresses[ChainIdEnum.BSC].brBUSD,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'Bearing BUSD',
      symbol: 'brBUSD',
      projectLink: '',
      tokenLogo: '/images/tokens/BUSD.png',
    },

    ankrBNB: {
      address: addresses[ChainIdEnum.BSC].ankrBNB,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'ankrBNB',
      symbol: 'ankrBNB',
      tokenLogo: '/images/tokens/ankrBNB.png',
    },
    ankrETH: {
      address: addresses[ChainIdEnum.BSC].ankrETH,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'ankrETH',
      symbol: 'ankrETH',
      tokenLogo: '/images/tokens/ankrETH.png',
    },

    WOM: {
      address: addresses[ChainIdEnum.BSC].WOM,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'WOM',
      symbol: 'WOM',
      tokenLogo: '/images/tokens/WOM.png',
    },
    MGP: {
      address: addresses[ChainIdEnum.BSC].MGP,
      chainId: ChainIdEnum.BSC,
      decimals: 18,
      isNative: false,
      name: 'MGP',
      symbol: 'MGP',
      tokenLogo: '/images/tokens/MGP.png',
    },
  },
}

// ADD NETWORK
export const TOKEN_UNKNOWNS = {
  [ChainIdEnum.SEPOLIA]: {
    address: ZERO_ADDRESS,
    chainId: ChainIdEnum.SEPOLIA,
    decimals: 18,
    isNative: true,
    name: 'Unknown',
    symbol: 'UNKNOWN',
    projectLink: '',
    tokenLogo: '/images/tokens/Unknown.png',
  },

  [ChainIdEnum.BSC_TESTNET]: {
    address: ZERO_ADDRESS,
    chainId: ChainIdEnum.SEPOLIA,
    decimals: 18,
    isNative: true,
    name: 'Unknown',
    symbol: 'UNKNOWN',
    projectLink: '',
    tokenLogo: '/images/tokens/Unknown.png',
  },

  [ChainIdEnum.BSC]: {
    address: ZERO_ADDRESS,
    chainId: ChainIdEnum.SEPOLIA,
    decimals: 18,
    isNative: true,
    name: 'Unknown',
    symbol: 'UNKNOWN',
    projectLink: '',
    tokenLogo: '/images/tokens/Unknown.png',
  },
}
