export const urlRoute = {
  home: () => ({
    route: '/',
    to: '/',
  }),
  pools: () => ({
    route: '/pools',
    to: '/pools',
  }),
  strategies: () => ({
    route: '/strategies',
    to: '/strategies',
  }),
  strategyDetail: ({ code }: { code: string }) => ({
    route: '/strategies/:code',
    to: `/strategies/${code}`,
  }),

  portfolio: () => ({
    route: '/portfolio',
    to: '/portfolio',
  }),
}
