import React from 'react'
import styled from 'styled-components'
import LayoutContent from './components/LayoutContent'
import Topbar from './components/Topbar'
import GuideFloat from 'views/StrategyDetails/components/GuideFloat'
import Menu from './components/Menu'

export const StyledLayoutWrapper = styled.div`
  min-height: 100vh;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Layout = ({ children, ...props }) => {
  return (
    <StyledLayoutWrapper>
      <Topbar />
      <Menu />
      <LayoutContent {...props}>{children}</LayoutContent>
      {/* <GuideFloat /> */}
    </StyledLayoutWrapper>
  )
}

export default Layout
