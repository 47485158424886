import BigNumber from 'bignumber.js'

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 30000

export const BIG_TEN = new BigNumber(10)
export const BIG_ZERO = new BigNumber(0)

export const LIQUIDATION_SAFE = 0.9

export const RESERVE_FACTOR = 0.9

export const OVERALL_APY = 20

export const DECIMALS_MULTIPLIER = 6
export const FIXED_HEALTH_FACTORY = 8

export const INITIAL_ALLOWED_SLIPPAGE = 400 // 4%

export const SLIPPAGE_UNIT = 10000 // 100%

export const MAX_LEVERAGE_MULTIPLIER = 10

export const INITIAL_AMOUNT_FAUCET = 1000 // 1k
export const INITIAL_AMOUNT_FAUCET_SMALL = 1 // 1k

export const UINT256_MAX = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const BASE_API = 'https://api.gentoo.finance'
