import TransactionUpdater from './state/transactions/updater'
import MulticallUpdater from './state/multicall/updater'

import { chains } from './packages/wagmi/wagmi'

export function Updaters() {
  return (
    <>
      {chains.map((chain) => (
        <TransactionUpdater key={`trxUpdater#${chain.id}`} />
      ))}
      <MulticallUpdater />
    </>
  )
}

export default Updaters
