import React from 'react'
import styled from 'styled-components'

import { colors } from 'theme/colors'
import Link from './Link'
import { LinkProps } from './types'
import { OpenNewIcon } from 'svgs'

const LinkExternal: React.FC<LinkProps> = ({ hideIcon, children, ...props }) => {
  return (
    <Link external {...props}>
      {children}
      {!hideIcon && (
        <OpenNewIcon fill={props.color ? colors[props.color] : colors.primary} style={{ marginLeft: '4px' }} />
      )}
    </Link>
  )
}

export default LinkExternal
