import BigNumber from 'bignumber.js'
import { BIG_ZERO, LIQUIDATION_SAFE, OVERALL_APY, RESERVE_FACTOR, ZERO_ADDRESS } from 'config'
import { getSlippageBalance } from './regex'

type Value = string | number | BigNumber

const toBN = (val: Value) => new BigNumber(val)

/*
  AmountMin = AmountDeposit * LeverageMultiplier - AmountDeposit * LeverageMultiplier * SlippageConfig
  -> SlippageConfig = 0.5,1,2,5%
*/

export const getAmountMin = (amountDeposit: Value, leverageMultiplier: Value, userSlippage: Value) => {
  const slippage = getSlippageBalance(toBN(userSlippage).toNumber())

  const amountDepositLeverageMultiplier = toBN(amountDeposit).multipliedBy(leverageMultiplier)

  const amountDepositSlippage = amountDepositLeverageMultiplier.multipliedBy(slippage)
  return amountDepositLeverageMultiplier.minus(amountDepositSlippage)
}

/*
  Overall APY = BaseAPY (5%) * LeverageMultiplier - Borrow Rate * (LeverageMultiplier - 1 )
*/
export const getOverallAPY = (leverageMultiplier: Value, borrowRate: Value, overallAPY: Value) => {
  const percent = toBN(overallAPY).dividedBy(100)
  const apy = percent
    .multipliedBy(leverageMultiplier)
    .minus(toBN(borrowRate).multipliedBy(toBN(leverageMultiplier).minus(1)))

  return apy.multipliedBy(100)
}

/*
  Liquidation Price = CurrentDepositTokenPriceUSD / HealthFactor
*/
export const getLiquidityPrice = (currentDepositTokenPriceUSD: BigNumber, healthFactor: BigNumber) => {
  return healthFactor.gt(0) && currentDepositTokenPriceUSD.gt(0)
    ? currentDepositTokenPriceUSD.dividedBy(healthFactor)
    : BIG_ZERO
}

export const isUnsafeLiquidation = (liquidationPrice: Value, depositTokenInUsdPrice: Value) =>
  toBN(liquidationPrice).dividedBy(depositTokenInUsdPrice).gt(LIQUIDATION_SAFE)

/*
  User leverage multilier = leveraged / principal ( only for method getAccountCreditInfo )
*/
export const getUserLeverageMultilier = (leveraged: BigNumber, principal: BigNumber) => {
  return principal.gt(0) && leveraged.gt(0) ? leveraged.dividedBy(principal) : BIG_ZERO
}

/*
  if the creditToken is 0x0000000000000000000000000000000000000000
   -> it means that the user has not deposited,
   <-> otherwise the user must borrow according to the creditToken
*/
export const getUserCreditToken = (creditTokenAddress: string) => {
  const creditToken = creditTokenAddress?.toString().toLowerCase()

  return !creditTokenAddress || creditToken === ZERO_ADDRESS ? null : creditToken
}

/*
  Net Profit = (  ( AccountValue * DepositTokenPrice ) - ( AccountDebts * BorrowTokenPrice ) / DepositTokenPrice  ) -  AccountPrincipal 

  const accountValueUsdPrice = AccountValue * DepositTokenPrice
  const accountDebtsUsdPrice = AccountDebts * BorrowTokenPrice
  const netProfit = ( ( accountValueUsdPrice  - accountDebtsUsdPrice ) / DepositTokenPrice ) - AccountPrincipal

  -> Net Profit is negative => account is being loss ( color red )
     Net Profit is positive => account is being profit ( color green )
*/

export const getNetProfit = (
  accountValue: Value,
  accountDebts: Value,
  accountPrincipal: Value,
  depositTokenPrice: Value,
  borrowTokenPrice: Value,
) => {
  if (!(toBN(depositTokenPrice).gt(0) && toBN(borrowTokenPrice).gt(0))) return BIG_ZERO

  const accountValueUsdPrice = toBN(accountValue).multipliedBy(depositTokenPrice)
  const accountDebtsUsdPrice = toBN(accountDebts).multipliedBy(borrowTokenPrice)
  const netProfit = accountValueUsdPrice
    .minus(accountDebtsUsdPrice)
    .dividedBy(depositTokenPrice)
    .minus(accountPrincipal)

  return netProfit
}

/*
  Burn Token
  burn = input value / bearingRate
*/
export const getBurnToken = (value: Value, bearingRate: Value) => {
  return toBN(value).dividedBy(bearingRate)
}

/*
  Total avaliable withdraw Token
  Total avaliable = totalAvailable / RESERVE_FACTOR
*/
export const getTotalAvailiableWithdraw = (totalAvailable: Value) => {
  return toBN(totalAvailable).multipliedBy(RESERVE_FACTOR)
}

/*
  Daily Earn
  Daily Earn: Overall APY * depositAmount / 365
*/
export const getDailyEarn = (overallAPY: Value, depositAmount: Value) => {
  return toBN(overallAPY).dividedBy(100).multipliedBy(depositAmount).dividedBy(365)
}

// Pools
/*
  supplyAPY = ( borrowRate * totalBorrowed ) / totalSupplied * 100

  => return APY rate %
*/
export const getSupplyRate = (borrowRate: Value, totalBorrowed: Value, totalSupplied: Value) => {
  return toBN(totalSupplied).gt(0) && toBN(totalBorrowed).gt(0)
    ? toBN(borrowRate).multipliedBy(totalBorrowed).dividedBy(totalSupplied).multipliedBy(100)
    : BIG_ZERO
}

/*
  amount after fee = value - value * fee / 100
*/

export const getAmountAfterStrategyFee = (value: Value, fee: Value) => {
  return toBN(value).minus(toBN(value).multipliedBy(fee).dividedBy(100))
}

/*
  amount Your Supply
*/
export const getAmountSuppliedBalance = (value: Value, bearingRate: Value) => {
  return toBN(value).multipliedBy(bearingRate)
}

/*
  amount PNL
*/
export const getPNLProfit = (profit: Value, principal: Value) => {
  return toBN(profit).dividedBy(principal).multipliedBy(100)
}
