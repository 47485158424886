import { ElementType, ReactNode } from 'react'
import { LayoutProps, SpaceProps } from 'styled-system'
import { Colors, Radii } from 'theme/types'
import { PolymorphicComponentProps } from 'utils/polymorphic'

export const variants = {
  PRIMARY: 'primary',
  PRIMARY_CONTRAST: 'primary-contrast',
  PRIMARY_OUTLINE: 'primary-outline',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  TEXT: 'text',
  DANGER: 'danger',
  SUBTLE: 'subtle',
  SUCCESS: 'success',
  LIGHT: 'light',
} as const

export const scales = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const

export type Variant = (typeof variants)[keyof typeof variants]
export type Scale = (typeof scales)[keyof typeof scales]

export interface BaseButtonProps extends LayoutProps, SpaceProps {
  as?: 'a' | 'button' | ElementType
  borderRadius?: keyof Radii
  external?: boolean
  isLoading?: boolean
  variant?: Variant
  scale?: Scale
  disabled?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  background?: Colors | string
}

export type ButtonProps<P extends ElementType = 'button'> = PolymorphicComponentProps<P, BaseButtonProps>
