import styled from 'styled-components'
import { PolymorphicComponent } from 'utils/polymorphic'
import Button from './Button'
import { BaseButtonProps } from './types'

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button).attrs({
  background: 'transparent',
})<BaseButtonProps>`
  padding: 0;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`

export default IconButton
