import { createReducer } from '@reduxjs/toolkit'
import { INITIAL_ALLOWED_SLIPPAGE } from 'config'
import { updateUserSlippageTolerance } from './actions'

export interface UserState {
  userSlippageTolerance: number
}

const initialState: UserState = {
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
}

const reducer = createReducer<UserState>(initialState, (builder) =>
  builder.addCase(updateUserSlippageTolerance, (state, action) => {
    state.userSlippageTolerance = action.payload.userSlippageTolerance
  }),
)

export default reducer
