import { Token } from './token'

export enum StrategyProtocol {
  AnyProtocol = 'Any Protocol',
  Wombat = 'Wombat',
  Magpie = 'Magpie',
  Ankr = 'Ankr',
  Venus = 'Venus',
  Biswap = 'Biswap',
}

export enum StrategyAsset {
  AnyAsset = 'Any Asset',
  Stablecoins = 'Stablecoins',
  BNB = 'BNB',
  ETH = 'ETH',
  VAI = 'VAI',
}

export const filtersStrategyProtocol = [
  StrategyProtocol.AnyProtocol,
  StrategyProtocol.Wombat,
  StrategyProtocol.Magpie,
  StrategyProtocol.Ankr,
  StrategyProtocol.Venus,
  StrategyProtocol.Biswap,
]

export const filtersStrategyAsset = [
  StrategyAsset.AnyAsset,
  StrategyAsset.Stablecoins,
  StrategyAsset.BNB,
  StrategyAsset.ETH,
]

export const filtersStablecoin = [StrategyAsset.VAI]

export class Strategy {
  name: string
  code: string
  address: string
  description: string
  fee: number
  depositTokens: Token[]
  yieldToken: Token[]
  borrows: Token[]
  range: [number, number]
  protocols: StrategyProtocol[]
  guide: {
    image: string
    description: string
  }
  apy: number
}

export interface PoolUserDataResponse {
  code: string
  allowance: string
  tokenBalance: string
  suppliedBalance: string
}
