import BigNumber from 'bignumber.js'
import { SLIPPAGE_UNIT } from 'config'

export const escapeRegExp = (value: string): string => {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

// match escaped "." characters via in a non-capturing group
export const numberRegExp = (value: string) => RegExp(`^\\d*(?:\\\\[.])?\\d*$`).test(escapeRegExp(value))

export const getSlippageBalance = (slippage: number) => new BigNumber(slippage).dividedBy(SLIPPAGE_UNIT)
