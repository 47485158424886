import Box from 'components/Box/Box'
import { ModalProps } from 'components/Modal'
import Overlay from 'components/Overlay'
import React, { createContext, useCallback, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

interface ModalsContext {
  onPresent: (id: string, Component: React.FC, data?: any) => void
  onDismiss: (id: string) => void
}

export interface IModalNavigation {
  onBack?: (param: any) => void
}

export const Context = createContext<ModalsContext>({
  onPresent: () => {},
  onDismiss: () => {},
})

const OpenModalEffect: React.FC<{ onClose: () => void; display?: string; children: any }> = ({ display, children }) => {
  return <StyledResponsiveWrapper display={display}>{children}</StyledResponsiveWrapper>
}

const Modals = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [contentId, setContentId] = useState<React.ReactNode>()
  const contentsRef = useRef([])

  const handlePresent = useCallback(
    (id: string, Component: React.FC<ModalProps>, data?: any) => {
      contentsRef.current.push({
        id,
        content: (
          <Component
            data={data}
            key={id}
            onDismiss={() => {
              handleDismiss(id)
            }}
          />
        ),
      })
      setContentId(id)
      setIsOpen(true)

      document.body.classList.add('no-scroll')
      document.body.classList.add('modal-open')
    },
    [setContentId],
  )

  const handleDismiss = useCallback(
    (id?: string) => {
      const contents = id
        ? contentsRef.current.filter((item) => item.id !== id)
        : contentsRef.current.slice(0, contentsRef.current.length - 1)
      contentsRef.current = contents

      if (contents.length === 0) {
        setIsOpen(false)
        return
      }

      setContentId(contentsRef.current[contentsRef.current.length - 1].id)
    },
    [setContentId, setIsOpen],
  )

  const closeModal = useCallback(() => {
    setIsOpen(false)
    contentsRef.current = []
    setContentId(undefined)
    document.body.classList.remove('no-scroll')
    document.body.classList.remove('modal-open')
  }, [setIsOpen])

  return (
    <Context.Provider
      value={{
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}
    >
      {children}
      {isOpen && (
        <StyledModalWrapper>
          {contentsRef.current.map((item) => (
            <OpenModalEffect onClose={closeModal} display={item.id === contentId ? 'flex' : 'none'} key={item.id}>
              {item.content}
            </OpenModalEffect>
          ))}
          <Overlay onClick={() => handleDismiss()} />
        </StyledModalWrapper>
      )}
    </Context.Provider>
  )
}

const openEffect = keyframes`
   from {
        transform: scale(0.95);
        opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
`

const StyledResponsiveWrapper = styled(Box)`
  position: fixed;
  z-index: 100;
  animation: ${openEffect} 0.2s;
`

const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`

export default Modals
