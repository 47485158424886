import { ChainIdEnum, listNetwork } from 'config/networks'

export function getExplorerLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: ChainIdEnum,
): string {
  switch (type) {
    case 'transaction': {
      return `${listNetwork[chainId].info.blockExplorers.default.url}/tx/${data}`
    }
    case 'token': {
      return `${listNetwork[chainId].info.blockExplorers.default.url}/token/${data}`
    }
    case 'block': {
      return `${listNetwork[chainId].info.blockExplorers.default.url}/block/${data}`
    }
    case 'countdown': {
      return `${listNetwork[chainId].info.blockExplorers.default.url}/block/countdown/${data}`
    }
    default: {
      return `${listNetwork[chainId].info.blockExplorers.default.url}/address/${data}`
    }
  }
}

export function getNameExplorer(chainId: ChainIdEnum): string {
  return listNetwork?.[chainId] && listNetwork?.[chainId].info.blockExplorers.default.name
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const copyToClipboardWithCommand = (text: string, cb?: () => void) => {
  if (navigator.clipboard && navigator.permissions) {
    navigator.clipboard.writeText(text).then(cb)
  } else if (document.queryCommandSupported('copy')) {
    const ele = document.createElement('textarea')
    ele.value = text
    document.body.appendChild(ele)
    ele.select()
    document.execCommand('copy')
    document.body.removeChild(ele)
    cb?.()
  }
}
