import { useState, useEffect, useMemo } from 'react'
import { useSID } from './useSid'

export const useSidName = (address: string) => {
  const [loading, setLoading] = useState(false)
  const [sidName, setSidName] = useState(undefined)
  const sid = useSID()

  const getName = async () => {
    setLoading(true)
    const data = await sid.getName(address)
    setSidName(data?.name || null)
    setLoading(false)
  }
  useEffect(() => {
    if (address && sid && !sidName) {
      getName()
    }
  }, [address, sid])

  return useMemo(
    () => ({
      loading,
      sidName,
    }),
    [loading, sidName],
  )
}
