import { useMemo } from 'react'
import { useAccount, useProvider, useSigner } from 'wagmi'
import useActiveWeb3React from './useActiveWeb3React'

export const useProviderOrSigner = (withSignerIfPossible = true) => {
  const { chainId } = useActiveWeb3React()
  const provider = useProvider({ chainId: chainId })
  const { address, isConnected } = useAccount()
  const { data: signer } = useSigner({
    chainId,
  })
  
  return useMemo(
    () => (withSignerIfPossible && address && isConnected && signer ? signer : provider),
    [address, isConnected, provider, signer, withSignerIfPossible],
  )
}
