import { CHAIN_MAINNET } from 'config/networks'
import { ConnectorNames } from 'packages/wagmi/wallet'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import {
  ConnectorNotFoundError,
  UserRejectedRequestError,
  useAccount,
  useChainId,
  useConnect,
  useDisconnect,
  useNetwork,
} from 'wagmi'
import useToast from './useToast'

const useAuth = () => {
  const dispatch = useAppDispatch()

  const { isConnecting } = useAccount()
  const { connectAsync, connectors, isLoading, error } = useConnect()
  const { toastError, toastInfo } = useToast()
  const { chain } = useNetwork()
  const { disconnect } = useDisconnect()
  const chainId = useChainId()

  const login = useCallback(
    async (connectorID: ConnectorNames, chainId = CHAIN_MAINNET) => {
      return new Promise(async (resolve, reject) => {
        try {
          await logout()
          const findConnector = connectors.find((c) => c.id === connectorID)
          const connected = await connectAsync({ connector: findConnector, chainId })
          resolve(connected)
        } catch (error: any) {
          if (error instanceof ConnectorNotFoundError) {
            toastError('Wallet is not found!', 'Please try again.')
          } else if (
            error.code === 4001 ||
            error instanceof UserRejectedRequestError ||
            error.message === 'Connection request reset. Please try again.'
          ) {
            toastInfo('Rejected.', 'Your wallet connection was rejected. Please try to connect again.')
          } else {
            toastError('Please confirm connect wallet.', 'Please check your wallet and confirm to connect Gentoo.')
          }
          reject(error)
        }
      })
    },
    [connectors, connectAsync, chainId],
  )

  const logout = useCallback(async () => {
    try {
      await disconnect()
    } catch (error) {
    } finally {
    }
  }, [disconnect, dispatch, chain?.id])

  return { login, logout, isLoading: isLoading || isConnecting, error }
}

export default useAuth
