import Image from 'components/Image'
import { Token } from 'config/types/token'
import React from 'react'
import { HelpIcon } from 'svgs'

const CurrencyLogo = ({ token, size = 32, style }: { token?: Token; size?: number; style?: React.CSSProperties }) => {
  return token ? (
    <Image width={size} height={size} src={token.tokenLogo} alt={`${token?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <HelpIcon />
  )
}
export default CurrencyLogo
