import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import IconButton from 'components/Button/IconButton'
import useKeydownEventListener from 'hooks/useKeydownEventListener'
import React from 'react'
import styled from 'styled-components'
import CloseIcon from 'svgs/close.svg'
import ModalBody from './components/ModalBody'
import ModalFooter from './components/ModalFooter'
import ModalHeader from './components/ModalHeader'
import { colors } from 'theme/colors'

export interface ModalProps<T = any> {
  id?: string
  onDismiss?: (id?: string) => void
  data?: T
}

const Modal: React.FC<ModalProps & BoxProps> = ({ id, onDismiss, children, ...props }) => {
  const handleOnDismiss = () => {
    if (onDismiss) {
      onDismiss(id)
    }
  }

  useKeydownEventListener((event) => {
    if (event && event.key === 'Escape') {
      handleOnDismiss()
    }
  })

  return (
    <StyledModal id={id} {...props}>
      <StyledCloseButton className="modal-closebutton" id="modal-closebutton" onClick={handleOnDismiss}>
        <CloseIcon width="24px" fill={colors.text} />
      </StyledCloseButton>
      {children}
    </StyledModal>
  )
}

const StyledModal = styled(Box)`
  box-sizing: border-box;

  border-top-left-radius: ${({ theme }) => theme.radii.small};
  border-top-right-radius: ${({ theme }) => theme.radii.small};

  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100vw - 24px);
  min-height: 100px;
  background: ${({ theme }) => theme.colors.cardBackground};
  max-width: 512px;
  max-height: calc(var(--screen-height) - 24px);
  overflow: auto;

  ${ModalHeader}, ${ModalBody}, ${ModalFooter} {
    padding: 12px;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    backdrop-filter: unset;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 480px;
    border-radius: ${({ theme }) => theme.radii.small};

    ${ModalHeader}, ${ModalBody}, ${ModalFooter} {
      padding: 20px;
    }
  }
`

const StyledCloseButton = styled(IconButton).attrs({ variant: 'text' })`
  position: absolute;
  right: 8px;
  top: 0px;
  z-index: 1000;

  svg {
    width: 20px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    right: 12px;
    top: 12px;
    svg {
      width: 24px;
    }
  }
`

export default Modal
