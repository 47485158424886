import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { APIEnum } from './ApiEnum'
import { BaseResponse, GentooRequest } from './types'
import Mapper from './mappers/Mapper'
import { BASE_API } from 'config'

const gentooAxios = axios.create({
  timeout: 30000,
  baseURL: BASE_API,
})

abstract class BaseRequest {
  protected get AxiosCancelToken() {
    const _cancelToken = axios.CancelToken
    return _cancelToken.source()
  }

  protected abstract _request(url: string, payload: any, config: any, mapperKey?: APIEnum): Promise<BaseResponse<any>>

  protected abstract callRequest(url: string, config: AxiosRequestConfig, payload?: any): Promise<any>

  protected abstract buildConfig(config: AxiosRequestConfig, mapper: any): AxiosRequestConfig
}

export abstract class GentooYieldRequest extends BaseRequest {
  protected async _get(url: string, params?: any, config?: AxiosRequestConfig, mapperKey?: string): Promise<any> {
    try {
      const mapper = Mapper.getMapper(mapperKey || url)
      const _config = this.buildConfig(config, mapper)
      const result = await gentooAxios.get(url, { params, ..._config })
      return result.data
    } catch (e) {
      console.error(`GET ${url}`, e)
      return null
    }
  }

  protected _post(
    url: string,
    payload?: any,
    config = {} as AxiosRequestConfig & { excludeErrors?: string[] },
    mapperKey?: string,
  ): GentooRequest<BaseResponse<any>> {
    const _source = this.AxiosCancelToken

    const call = async (): Promise<BaseResponse<any>> => {
      const response = await this._request(
        url,
        payload,
        {
          ...config,
          cancelToken: _source.token,
        },
        mapperKey,
      )

      return response
    }

    return {
      call,
      cancel: () => {
        _source.cancel()
      },
    }
  }

  protected async _request(
    url: string,
    payload?: any,
    config = {} as AxiosRequestConfig & { excludeErrors?: string[]; disabledToast?: boolean },
    mapperKey?: string,
  ): Promise<BaseResponse<any>> {
    try {
      const mapper = Mapper.getMapper(mapperKey || url)
      const { excludeErrors = [], ...axiosConfig } = config
      const _config = this.buildConfig(axiosConfig, mapper)

      const result: AxiosResponse<BaseResponse<any>> = await this.callRequest(url, _config, payload)
      if (result?.data?.code === 'success') return result.data

      if (result?.data?.code === 'error_country_banned') {
        return result.data
      }

      if (result?.data && result.data.code === 'error_auth_blocked') {
        window.location.replace('/403')
      } else if (result?.data && result.data.error !== 'success') {
        // if (!config.disabledToast) GentooToast.error(result.data.message)

        const message = `API ${url} Failed`
        // logError(message, { message, extra: { response: result.data, payload }, tags: ['api_failed'] })
      }

      return result.data
    } catch (e) {
      if (!axios.isCancel(e)) {
        // logError(`CALL ${url}`, e)
      }
      return null
    }
  }

  protected async callRequest(url: string, config: AxiosRequestConfig, payload?: any) {
    return gentooAxios.post(url, payload, config)
  }

  protected buildConfig(config: AxiosRequestConfig, mapper: any): AxiosRequestConfig {
    let _config = {
      // withCredentials: true,
      ...config,
    }

    if (mapper) {
      _config = { ..._config, transformResponse: mapper }
    }

    return _config
  }
}
