import { createReducer } from '@reduxjs/toolkit'
import { resetActionsPortfolio, updateActionsPortfolio, updateSearchAccountPortfolio } from './actions'
import { PortfolioState } from './types'

const initialState: PortfolioState = {
  addLiquidities: [],
  removeLiquidities: [],
  boxCreditedDeposits: [],
  boxCreditedWithdraws: [],
  boxCreditedLiquidates: [],

  queryAccount: '',
}

const portfolioReducer = createReducer<PortfolioState>(initialState, (builder) =>
  builder
    .addCase(updateActionsPortfolio, (state, { payload }) => ({
      ...state,
      addLiquidities: payload.addLiquidities,
      removeLiquidities: payload.removeLiquidities,
      boxCreditedDeposits: payload.boxCreditedDeposits,
      boxCreditedWithdraws: payload.boxCreditedWithdraws,
      boxCreditedLiquidates: payload.boxCreditedLiquidates,
    }))
    .addCase(resetActionsPortfolio, (state) => ({
      ...state,
      addLiquidities: [],
      removeLiquidities: [],
      boxCreditedDeposits: [],
      boxCreditedWithdraws: [],
      boxCreditedLiquidates: [],
    }))
    .addCase(updateSearchAccountPortfolio, (state, { payload }) => ({
      ...state,
      queryAccount: payload,
    })),
)

export default portfolioReducer
