import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { WalletConfig } from 'packages/ui-wallets/types'
import { createWallets } from 'packages/wagmi/wallet'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { useConnect } from 'wagmi'
import { setMenuOpen, setSelectedWallet } from './actions'

export const useMenuContainer = () => {
  const dispatch = useAppDispatch()
  const isMenuOpen = useAppSelector((state) => state.app.isMenuOpen)
  const toggleMenu = useCallback(() => {
    dispatch(setMenuOpen({ isOpen: !isMenuOpen }))
  }, [isMenuOpen])

  return { toggleMenu, isMenuOpen }
}

export const useSelectedWallet = <T extends any>(): [
  WalletConfig<T> | null,
  (walletId: string) => void,
  WalletConfig<T>[],
] => {
  const dispatch = useAppDispatch()
  const walletId = useAppSelector((state) => state.app.walletId)
  const { chainId } = useActiveWeb3React()
  const { connectAsync } = useConnect()

  const wallets = createWallets(chainId, connectAsync) as WalletConfig<T>[]

  const setUserSelectedWallet = useCallback(
    (walletId: string) => {
      dispatch(setSelectedWallet({ walletId: walletId }))
    },
    [dispatch],
  )

  const wallet = useMemo(() => wallets.find((wallet) => wallet.id === walletId) || null, [wallets, walletId])
  return [wallet, setUserSelectedWallet, wallets]
}
