import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Dropdown from 'components/Dropdown'
import useAuth from 'hooks/useAuth'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import CircleLoader from 'components/Loader/CircleLoader'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Text from 'components/Text/Text'
import { INITIAL_AMOUNT_FAUCET, INITIAL_AMOUNT_FAUCET_SMALL } from 'config'
import { ChainIdEnum } from 'config/networks'
import { TOKEN_FAUCET } from 'config/tokenList'
import { Token } from 'config/types/token'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useProviderOrSigner } from 'hooks/useProviderOrSigner'
import { RowMiddle } from 'layout/components/Row'
import { css } from 'styled-components'
import { compareStringValue } from 'utils/addressHelpers'
import { getErc20FaucetContract } from 'utils/contractHelpers'
import { getDecimalAmount } from 'utils/formatBalance'
import BoxGradient from 'components/Box/BoxGradient'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useAccount } from 'wagmi'

const StyledBoxGradient = styled(BoxGradient)`
  box-sizing: border-box;
  width: 70px;
  padding: 6px 12px;
  height: 42px;
`

const WrapperTokenItem = styled(Flex)<{ disabled: boolean }>`
  transition: ${({ theme }) => theme.transitions.fast};
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? css`
          user-select: none;
          opacity: 0.5;
        `
      : css`
          :hover {
            background: ${({ theme }) => theme.colors.hover};

            ${Text} {
              color: ${({ theme }) => theme.colors.text};
            }
          }
        `}
`

const TokenItem: React.FC<{ token: Token } & BoxProps> = ({ token, ...props }) => {
  return (
    <RowMiddle flex={1} width="100%" {...props}>
      <Box width={24} height={24}>
        <CurrencyLogo token={token} />
      </Box>

      <Text color={'textSubtle'} ml="8px">
        Get{' '}
        {token.symbol === 'USDT' || token.symbol === 'BUSD' || token.symbol === 'VAI' || token.symbol === 'BSW'
          ? '1k'
          : '1'}{' '}
        {token.symbol}
      </Text>
    </RowMiddle>
  )
}
const UserFaucet: React.FC<BoxProps> = ({ ...props }) => {
  const { chainId } = useActiveWeb3React()
  const providerOrSigner = useProviderOrSigner(true)
  const { isConnected } = useAccount()

  const { logout } = useAuth()

  const onModalInfoWallet = () => {}
  const [isMinting, setMinting] = useState('')

  const tokens = useMemo(() => TOKEN_FAUCET[chainId] || TOKEN_FAUCET[ChainIdEnum.BSC_TESTNET], [chainId])

  const handleMintFaucet = (token: Token) => {
    const contract = getErc20FaucetContract(token.address, chainId, providerOrSigner)
    const decimalsAmount = getDecimalAmount(
      token.symbol === 'USDT' || token.symbol === 'BUSD' || token.symbol === 'VAI' || token.symbol === 'BSW'
        ? INITIAL_AMOUNT_FAUCET
        : INITIAL_AMOUNT_FAUCET_SMALL,
      token.decimals,
    ).toString(10)
    setMinting(token.address)

    contract
      .mint(decimalsAmount)
      .then((data) => {
        setMinting(null)
      })
      .catch((error) => {
        setMinting(null)
      })
  }

  const DropdownContent = useMemo(() => {
    return (
      <Flex flexDirection="column" minWidth="228px" maxWidth="400px">
        {tokens.map((token) => {
          return (
            <WrapperTokenItem
              key={token.address}
              p="16px"
              alignItems="center"
              onClick={() => handleMintFaucet(token)}
              disabled={!!isMinting}
            >
              <TokenItem token={token} />

              {compareStringValue(token.address, isMinting) && (
                <Box ml="12px">
                  <CircleLoader />
                </Box>
              )}
            </WrapperTokenItem>
          )
        })}
      </Flex>
    )
  }, [chainId, isMinting, logout, onModalInfoWallet])
  const { isDesktop } = useMatchBreakpoints()

  return (
    isConnected && (
      <Dropdown
        background="transparent"
        style={{
          backgroundColor: 'transparent',
        }}
        p="0 !important"
        dropdownContent={DropdownContent}
        dropdownPosition={isDesktop ? 'bottom-right' : 'bottom-left'}
        {...props}
      >
        <StyledBoxGradient>
          <Text fontWeight={500}>Faucet</Text>
        </StyledBoxGradient>
      </Dropdown>
    )
  )
}

export default React.memo(UserFaucet)
