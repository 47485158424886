import Button from 'components/Button'
import { ButtonProps } from 'components/Button/types'
import Dots from 'components/Loader/Dots'
import { CHAIN_MAINNET, listNetwork } from 'config/networks'
import React, { useMemo } from 'react'
import { useChainId, useSwitchNetwork } from 'wagmi'

const SwitchButton: React.FC<ButtonProps> = ({ ...props }) => {
  const chainId = useChainId()

  // TODO MAINNET
  const network = useMemo(() => listNetwork[CHAIN_MAINNET], [chainId])
  const { switchNetwork, isLoading } = useSwitchNetwork()

  return (
    <Button disabled={isLoading} onClick={() => switchNetwork(chainId)} {...props} style={{ fontSize: 14 }}>
      {isLoading ? <Dots>Switching to {network.info.name}</Dots> : `Switch to ${network.info.name} to start!`}
    </Button>
  )
}

export default SwitchButton
