import { POOLS_MARKET } from 'config/pool'
import { DeserializePoolState, DeserializedPool } from 'config/types/pool'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useFastRefreshEffect, useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState, useAppDispatch, useAppSelector } from 'state'

import { deserializePool } from './poolConverter'
import {
  clearPoolUserData,
  fetchPoolUserDataAsync,
  fetchPoolsPublicDataAsync,
  fetchTokenPoolInUsdPrice,
} from './actions'

export const usePollPoolsWithUserData = () => {
  const { account, chainId } = useActiveWeb3React()
  const userDataLoaded = useAppSelector((state) => state.pools.userDataLoaded)

  const dispatch = useAppDispatch()

  const getPools = () => {
    const poolsToFetch = POOLS_MARKET[chainId] ?? []
    return poolsToFetch.map((poolToFetch) => poolToFetch.code)
  }

  useFastRefreshEffect(() => {
    const codes = getPools()
    dispatch(fetchTokenPoolInUsdPrice({ codes, chainId }))
  }, [dispatch, account, chainId])

  useSlowRefreshEffect(() => {
    const codes = getPools()
    dispatch(fetchPoolsPublicDataAsync({ codes, chainId }))

    if (account) {
      dispatch(fetchPoolUserDataAsync({ account, codes, chainId }))
    } else if (userDataLoaded && !account) {
      dispatch(clearPoolUserData())
    }
  }, [dispatch, account, chainId])
}

export const usePools = (): DeserializePoolState => {
  const { chainId } = useActiveWeb3React()
  const pools = useSelector((state: AppState) => state.pools)
  const deserializedPoolsData = pools.data[chainId]?.map(deserializePool) || []

  const { userDataLoaded } = pools

  return useMemo(
    () => ({
      userDataLoaded,
      data: deserializedPoolsData,
    }),
    [userDataLoaded, deserializedPoolsData, chainId],
  )
}

export const useMyPools = (): DeserializePoolState => {
  const { data, userDataLoaded } = usePools()

  return useMemo(() => {
    return {
      userDataLoaded,
      data: data
        .map((pool) => {
          if (pool.userData.suppliedBalance.gt(0)) {
            return {
              ...pool,
              totalPoold: pool.userData.suppliedBalance,
            }
          } else {
            return null
          }
        })
        .filter((item) => item),
    }
  }, [userDataLoaded, data])
}

export const usePoolFromCode = (code: string): DeserializedPool => {
  const { chainId } = useActiveWeb3React()
  const pool = useAppSelector((state) => state.pools.data[chainId]?.find((f) => f.code === code))
  return useMemo(() => pool && deserializePool(pool), [pool])
}

export const usePoolUser = (code: string) => {
  const { userData } = usePoolFromCode(code)
  return userData
}
