import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'config'
import { DeserializedPool, DeserializedPoolUserData, Pool } from 'config/types/pool'
import { getBalanceAmount } from 'utils/formatBalance'
import { getAmountSuppliedBalance } from 'utils/reciept-base'

const convertUserData = (pool: Pool): DeserializedPoolUserData => {
  const { bearingRate, userData } = pool || {}

  const suppliedBalance = new BigNumber(userData.suppliedBalance) || BIG_ZERO
  const allowance = userData.allowance || BIG_ZERO
  const tokenBalance = userData.tokenBalance || BIG_ZERO

  const prasedBearingRate = getBalanceAmount(bearingRate, pool.suppliedToken.decimals)

  const withdrawBalance =
    suppliedBalance.gt(BIG_ZERO) && prasedBearingRate.gt(BIG_ZERO)
      ? new BigNumber(
          getAmountSuppliedBalance(suppliedBalance, prasedBearingRate).toFixed(
            pool.token.decimals,
            BigNumber.ROUND_DOWN,
          ),
        )
      : BIG_ZERO

  return {
    suppliedBalance: getBalanceAmount(suppliedBalance, pool.suppliedToken.decimals),
    allowance: getBalanceAmount(allowance, pool.token.decimals),
    tokenBalance: getBalanceAmount(tokenBalance, pool.token.decimals),
    withdrawBalance: getBalanceAmount(withdrawBalance, pool.token.decimals),
  }
}

export const deserializePool = (pool: Pool): DeserializedPool => {
  const { bearingRate, tokenInUsdPrice, totalSupplied, totalBorrowed, totalAvailable, borrowRate } = pool

  return {
    ...pool,
    apy: {
      borrow: Number(getBalanceAmount(pool.apy?.borrow, 0).toNumber().toFixed(2)) || 0,
      supply: Number(getBalanceAmount(pool.apy?.supply, 0).toNumber().toFixed(2)) || 0,
    },
    tokenInUsdPrice: tokenInUsdPrice ? getBalanceAmount(tokenInUsdPrice) : BIG_ZERO,
    borrowRate: borrowRate ? getBalanceAmount(borrowRate, pool.token.decimals) : BIG_ZERO,
    totalBorrowed: totalBorrowed ? getBalanceAmount(totalBorrowed, pool.token.decimals) : BIG_ZERO,
    totalSupplied: totalSupplied ? getBalanceAmount(totalSupplied, pool.token.decimals) : BIG_ZERO,
    totalAvailable: totalAvailable ? getBalanceAmount(totalAvailable, pool.token.decimals) : BIG_ZERO,
    bearingRate: bearingRate ? getBalanceAmount(new BigNumber(bearingRate), pool.token.decimals) : BIG_ZERO,
    userData: convertUserData(pool),
  }
}
