import { NETWORK_IDS, listNetwork } from 'config/networks'
import memoize from 'lodash/memoize'
import { configureChains, createClient } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectLegacyConnector } from 'wagmi/connectors/walletConnectLegacy'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { TrustWalletConnector } from './connectors/trustwallet'
import { BloctoConnector } from './connectors/blocto'
import { BinanceWalletConnector } from './connectors/binanceWallet'
import { LedgerConnector } from 'wagmi/connectors/ledger'

const CHAINS = NETWORK_IDS.map((id) => listNetwork[id].info)

export const { provider, chains } = configureChains(CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      return { http: chain.rpcUrls.default.http[0] }
    },
  }),
])

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'Gentoo Finance',
    appLogoUrl: 'https://gentoo.finance/images/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrModalOptions: {
      themeVariables: {
        '--wcm-z-index': '100',
      },
    },
    metadata: {
      name: 'Gentoo Finance',
      description: 'Gentoo Finance | Earn yield by lending your assets or boost yield by leveraging strategies',
      url: 'https://gentoo.finance',
      icons: ['https://gentoo.finance/images/logo.png'],
    },
    projectId: process.env.NEXT_PUBLIC_CONNECT_WALLET_PROJECT_ID,
    showQrModal: true,
  },
})

export const walletConnectNoQrCodeConnector = new WalletConnectLegacyConnector({
  chains,
  options: {
    qrcode: false,
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const trustWalletConnector = new TrustWalletConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})
export const bscConnector = new BinanceWalletConnector({ chains })

const bloctoConnector = new BloctoConnector({
  chains,
  options: {
    defaultChainId: 56,
    appId: 'e2f2f0cd-3ceb-4dec-b293-bb555f2ed5af',
  },
})

const ledgerConnector = new LedgerConnector({
  chains,
  options: {
    // projectId: 'e542ff314e26ff34de2d4fba98db70bb',
  },
})
export const client = createClient({
  autoConnect: true,
  provider,
  connectors: [
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    bscConnector,
    // @ts-ignore FIXME: wagmi
    // bloctoConnector,
    // ledgerConnector,
    trustWalletConnector,
  ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)
