import { ChainIdEnum } from './networks'
import { TOKENS } from './tokens'
import { Token } from './types/token'

// ADD NETWORK
export const TOKEN_LIST: { [id in ChainIdEnum]: Token[] } = {
  [ChainIdEnum.SEPOLIA]: [TOKENS[ChainIdEnum.SEPOLIA].USDC, TOKENS[ChainIdEnum.SEPOLIA].WETH],
  [ChainIdEnum.BSC_TESTNET]: [
    TOKENS[ChainIdEnum.BSC_TESTNET].USDC,
    TOKENS[ChainIdEnum.BSC_TESTNET].USDT,
    TOKENS[ChainIdEnum.BSC_TESTNET].BUSD,
    TOKENS[ChainIdEnum.BSC_TESTNET].WBNB,
  ],
  [ChainIdEnum.BSC]: [
    TOKENS[ChainIdEnum.BSC].USDC,
    TOKENS[ChainIdEnum.BSC].USDT,
    TOKENS[ChainIdEnum.BSC].BUSD,
    TOKENS[ChainIdEnum.BSC].WBNB,
  ],
}

// ADD NETWORK
export const TOKEN_FAUCET: { [id in ChainIdEnum]: Token[] } = {
  [ChainIdEnum.SEPOLIA]: [],
  [ChainIdEnum.BSC_TESTNET]: [
    // TOKENS[ChainIdEnum.BSC_TESTNET].USDC,
    TOKENS[ChainIdEnum.BSC_TESTNET].USDT,
    TOKENS[ChainIdEnum.BSC_TESTNET].BUSD,
    TOKENS[ChainIdEnum.BSC_TESTNET].WBNB,
    TOKENS[ChainIdEnum.BSC_TESTNET].ETH,
    TOKENS[ChainIdEnum.BSC_TESTNET].VAI,
    TOKENS[ChainIdEnum.BSC_TESTNET].BSW,
  ],
}

// ADD NETWORK
export const NATIVE_TOKENS: { [id in ChainIdEnum]: Token } = {
  [ChainIdEnum.SEPOLIA]: TOKENS[ChainIdEnum.SEPOLIA].ETH,
  [ChainIdEnum.BSC_TESTNET]: TOKENS[ChainIdEnum.BSC_TESTNET].BNB,
  [ChainIdEnum.BSC]: TOKENS[ChainIdEnum.BSC].BNB,
}

// ADD NETWORK
export const WRAP_NATIVE_TOKENS: { [id in ChainIdEnum]: Token } = {
  [ChainIdEnum.SEPOLIA]: TOKENS[ChainIdEnum.SEPOLIA].WETH,
  [ChainIdEnum.BSC_TESTNET]: TOKENS[ChainIdEnum.BSC_TESTNET].WBNB,
  [ChainIdEnum.BSC]: TOKENS[ChainIdEnum.BSC].WBNB,
}
