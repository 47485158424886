import { ORACLE_ABI } from 'config/abi'
import { ChainIdEnum } from 'config/networks'
import { Pool } from 'config/types/pool'
import { getOracleAddress } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'

const fetchTokenPoolsUsdPrice = async (poolsToFetch: Pool[], chainId: ChainIdEnum): Promise<Pool[]> => {
  const poolCalls = poolsToFetch.map((pool) => ({
    address: getOracleAddress(chainId),
    name: 'getTokenPrice',
    params: [pool.token.address],
  }))

  const poolResult = await multicallv2(ORACLE_ABI, poolCalls, chainId)
  return poolsToFetch.map((pool, index) => {
    const [tokenInUsdPrice] = poolResult[index]

    return {
      ...pool,
      tokenInUsdPrice: tokenInUsdPrice.toString(),
    } as Pool
  })
}

export default fetchTokenPoolsUsdPrice
