import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import IconButton from 'components/Button/IconButton'
import Image from 'components/Image/Image'
import Link from 'components/Link/Link'
import Text from 'components/Text/Text'
import { navigationItems } from 'config/navigation'
import { useMenuContainer } from 'state/app/hooks'
import styled from 'styled-components'
import { CloseIcon } from 'svgs'
import theme from 'theme'
import { RowFixed } from '../Row'

const StyledContainer = styled(Box)`
  width: 100%;
  min-width: ${({ theme }) => theme.navigationWidth}px;
  padding: 0 12px;
  overflow-y: auto;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: ${({ theme }) => theme.navigationWidth}px;
  }
`

const StyledMenu = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 0;
`

const StyledLogo = styled(Image)`
  width: 40px;
  height: 40px;
  transform: translateY(-4px);
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 50px;
    height: 50px;
  }
`

const MenuContent = ({ small }) => {
  const { toggleMenu, isMenuOpen } = useMenuContainer()

  return (
    <StyledContainer>
      <Flex height={theme.topbarHeight + 'px'} alignItems="center" justifyContent="space-between" flex="unset">
        <RowFixed mt="4px">
          <StyledLogo src="/images/logo.png" width={50} height={50} alt="logo-web" />
          <Text ml={['6px', '', '12px']} bold fontSize={['18px', '', '26px']}>
            Gentoo
          </Text>
        </RowFixed>

        <IconButton onClick={toggleMenu}>
          <CloseIcon fill="#FFF" />
        </IconButton>
      </Flex>

      <Box>
        {navigationItems.map((nav) => {
          return (
            <StyledMenu
              onClick={() => {
                if (isMenuOpen) {
                  toggleMenu()
                }
              }}
              key={`navigation-${nav.label}`}
              href={nav.href}
            >
              {nav.label}
            </StyledMenu>
          )
        })}
      </Box>
    </StyledContainer>
  )
}

export default MenuContent
