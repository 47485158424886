import { captureException } from '@sentry/nextjs';

const assignError = (maybeError: any) => {
  if (typeof maybeError === 'string') {
    return new Error(maybeError);
  }
  if (typeof maybeError === 'object') {
    const error = new Error(maybeError?.message ?? String(maybeError));
    if (maybeError?.stack) {
      error.stack = maybeError.stack;
    }
    if (maybeError?.code) {
      error.name = maybeError.code;
    }
    return error;
  }
  return maybeError;
};

const ENABLED_LOG = true;

export const logError = (desc: string, error: Error | unknown) => {
  if (ENABLED_LOG) {
    if (error instanceof Error) {
      captureException(error);
    } else {
      captureException(assignError(error), error);
    }
  }
  console.error(`Logger: ${desc}`, { error });
};
