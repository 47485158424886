import useComponentOnScreen from 'hooks/useComponentOnScreen'
import useNextImage from 'hooks/useNextImage'
import React from 'react'
import Placeholder from './Placeholder'
import StyledImage from './StyledImage'
import StyledNextImage from './StyledNextImage'
import Wrapper from './Wrapper'
import observerOptions from './observerOptions'
import { ImageProps } from './types'

const Image: React.FC<ImageProps> = ({ src, alt, width, height, disabledLazyLoad, original, ...props }) => {
  const { isLoaded, ref } = useComponentOnScreen(observerOptions)
  const { isNextImgSupport } = useNextImage(src)

  return (
    <Wrapper ref={ref} height={height} width={width} {...props}>
      {src ? (
        isNextImgSupport ? (
          <>
            {isLoaded || disabledLazyLoad ? (
              <StyledNextImage
                src={src}
                alt={alt || 'gentoo image'}
                height={height}
                width={width}
                quality={original ? 100 : 80}
                priority
                objectFit="fill"
              />
            ) : (
              <Placeholder $height={height} $width={width} />
            )}
          </>
        ) : (
          <StyledImage src={src as string} alt={alt} />
        )
      ) : (
        <Placeholder $height={height} $width={width} />
      )}
    </Wrapper>
  )
}

export default Image
