import { RowCenter } from 'layout/components/Row'
import Box from './Box'
import { BoxProps } from './types'
import styled from 'styled-components'
import theme from 'theme'

const BoxGradient: React.FC<BoxProps> = ({
  children,
  height = 'auto',
  width = 'auto',
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  background = theme.colors.cardBackground,
  ...props
}) => {
  return (
    <StyledDropdown
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minWidth={minWidth}
      minHeight={minHeight}
      width={width}
      height={height}
      {...props}
    >
      <StyledWrapper
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        minWidth={minWidth}
        minHeight={minHeight}
        width={'calc(100%)'}
        height={'calc(100%)'}
        background={background + ' !important'}
      >
        {children}
      </StyledWrapper>
    </StyledDropdown>
  )
}

const StyledDropdown = styled(Box)`
  background: ${({ theme }) => theme.colors.gradients.button};
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;

  padding: 1px !important;
  box-sizing: content-box;
  border-radius: ${({ theme }) => theme.radii.small};
`

const StyledWrapper = styled(RowCenter)`
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.radii.small};
`

export default BoxGradient
