import React from 'react'
import useWalletModal from 'hooks/useWalletModal'
import Button from 'components/Button'
import { ButtonProps } from 'components/Button/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import SwitchButton from './SwitchButton'

const ConnectButton: React.FC<ButtonProps> = (props) => {
  const { onPresentConnectModal } = useWalletModal()
  const { isWrongChainId } = useActiveWeb3React()

  if (isWrongChainId) {
    return <SwitchButton {...props} />
  }

  return (
    <Button borderRadius="small" height="40px" onClick={onPresentConnectModal} {...props}>
      Connect Wallet
    </Button>
  )
}

export default ConnectButton
