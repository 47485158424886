import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ChainIdEnum } from 'config/networks'
import { PoolUserDataResponse } from 'config/types/strategy'
import stringify from 'fast-json-stable-stringify'
import type { AppState } from 'state'
import { fetchUserPoolsData } from './fetchPoolUser'
import fetchPools from './fetchPools'
import fetchTokenPoolsUsdPrice from './fetchTokenPoolsUsdPrice'
import { Pool } from 'config/types/pool'
import { POOLS_MARKET } from 'config/pool'

// Async thunks
export const fetchPoolsPublicDataAsync = createAsyncThunk<
  { pools: Pool[]; chainId: ChainIdEnum },
  {
    codes: string[]
    chainId: ChainIdEnum
  },
  {
    state: AppState
  }
>(
  'pools/fetchPoolsPublicDataAsync',
  async ({ codes, chainId }) => {
    const poolsToFetch = POOLS_MARKET[chainId].filter((poolConfig) => codes.includes(poolConfig.code))
    const pools = await fetchPools(poolsToFetch, chainId)
    return { pools: pools, chainId }
  },
  {
    condition: (arg, { getState }) => {
      const { pools } = getState()
      if (pools.loadingKeys[stringify({ type: fetchPoolsPublicDataAsync.typePrefix, arg })]) {
        return false
      }
      return true
    },
  },
)

export const clearPoolUserData = createAction('pools/clearPoolUserData')

export const fetchPoolUserDataAsync = createAsyncThunk<
  {
    pools: PoolUserDataResponse[]
    chainId: ChainIdEnum
  },
  {
    account: string
    codes: string[]
    chainId: ChainIdEnum
  },
  {
    state: AppState
  }
>(
  'pools/fetchPoolUserDataAsync',
  async ({ account, codes, chainId }) => {
    const poolsToFetch = POOLS_MARKET[chainId].filter((poolConfig) => codes.includes(poolConfig.code))
    const userPoolsData = await fetchUserPoolsData(account, poolsToFetch, chainId)
    return {
      pools: userPoolsData.map((data, index) => {
        return {
          code: poolsToFetch[index].code,
          allowance: data.allowance,
          tokenBalance: data.tokenBalance,
          suppliedBalance: data.suppliedTokenBalance,
        }
      }),
      chainId,
    }
  },
  {
    condition: (arg, { getState }) => {
      const { pools } = getState()
      if (pools.loadingKeys[stringify({ type: fetchPoolUserDataAsync.typePrefix, arg })]) {
        console.debug('pools user action is fetching, skipping here')
        return false
      }
      return true
    },
  },
)

// Async thunks
export const fetchTokenPoolInUsdPrice = createAsyncThunk<
  { pools: Pool[]; chainId: ChainIdEnum },
  {
    codes: string[]
    chainId: ChainIdEnum
  },
  {
    state: AppState
  }
>(
  'pools/fetchTokenPoolInUsdPrice',
  async ({ codes, chainId }) => {
    const poolsToFetch = POOLS_MARKET[chainId].filter((poolConfig) => codes.includes(poolConfig.code))
    const pools = await fetchTokenPoolsUsdPrice(poolsToFetch, chainId)
    return { pools: pools, chainId }
  },
  {
    condition: (arg, { getState }) => {
      const { pools } = getState()
      if (pools.loadingKeys[stringify({ type: fetchPoolsPublicDataAsync.typePrefix, arg })]) {
        return false
      }
      return true
    },
  },
)
