import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/networks'
import { Pool } from 'config/types/pool'
import { fetchPublicPoolsData } from './fetchPublicPoolsData'
import { getSupplyRate } from 'utils/reciept-base'
import { getBalanceAmount } from 'utils/formatBalance'

const fetchPools = async (dpoolsToFetch: Pool[], chainId: ChainIdEnum): Promise<Pool[]> => {
  const poolResult = await fetchPublicPoolsData(dpoolsToFetch, chainId)

  return dpoolsToFetch.map((pool, index) => {
    const [breaingRate, assets] = poolResult[index]

    const totalBorrowed = new BigNumber(assets.totalBorrowed.toString(10))
    const totalSupplied = new BigNumber(assets.totalSupplied.toString(10))
    const borrowRate = new BigNumber(assets.borrowRate.toString(10))

    const bearing = new BigNumber(breaingRate[0].toString(10))

    const borrowAPY = getBalanceAmount(borrowRate.multipliedBy(100), pool.token.decimals)
    const poolAPY = getBalanceAmount(getSupplyRate(borrowRate, totalBorrowed, totalSupplied), pool.token.decimals)

    const totalAvailable = totalSupplied.minus(totalBorrowed)

    return {
      ...pool,
      apy: {
        borrow: borrowAPY.toJSON(),
        supply: poolAPY.toJSON(),
      },
      borrowRate: borrowRate.toJSON(),
      bearingRate: bearing.toJSON(),
      totalBorrowed: totalBorrowed.toJSON(),
      totalSupplied: totalSupplied.toJSON(),
      totalAvailable: totalAvailable.toJSON(),
    } as Pool
  })
}

export default fetchPools
