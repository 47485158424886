import type { Signer } from '@ethersproject/abstract-signer'
import { Contract } from '@ethersproject/contracts'
import type { Provider } from '@ethersproject/providers'
import { ChainIdEnum } from 'config/networks'
import { provider } from '../packages/wagmi/wagmi'
import { getMulticallAddress } from './addressHelpers'
import { ERC20_ABI, ERC20_FAUCET_ABI, MULTICALL_ABI } from 'config/abi'
import { Erc20, Erc20Faucet, Multicall } from 'config/abi/types'

export const getContract = ({
  abi,
  address,
  signer,
  chainId,
}: {
  abi: any
  address: string
  chainId: ChainIdEnum
  signer?: Signer | Provider
}) => {
  const signerOrProvider = signer ?? provider({ chainId })
  return new Contract(address, abi, signerOrProvider)
}

export const getMulticallContract = (chainId: ChainIdEnum, signer?: Signer | Provider) => {
  return getContract({ abi: MULTICALL_ABI, address: getMulticallAddress(chainId), chainId, signer }) as Multicall
}

export const getErc20Contract = (address: string, chainId: ChainIdEnum, signer?: Signer | Provider) => {
  return getContract({ abi: ERC20_ABI, address: address, chainId, signer }) as Erc20
}
export const getErc20FaucetContract = (address: string, chainId: ChainIdEnum, signer?: Signer | Provider) => {
  return getContract({ abi: ERC20_FAUCET_ABI, address: address, chainId, signer }) as Erc20Faucet
}
