import { ChainIdEnum } from 'config/networks'

export type Address = {
  [chainIdEnum in ChainIdEnum]?: string
}

export enum FetchStatus {
  Idle = 'IDLE',
  Fetching = 'FETCHING',
  Fetched = 'FETCHED',
  Failed = 'FAILED',
}
