import { bsc, bscTestnet, mainnet, sepolia } from 'wagmi/chains'
import { Network } from './types/network'

type ListNetworkByChainId = {
  [key: string]: Network
}

export enum ChainIdEnum {
  ETH = mainnet.id,
  SEPOLIA = sepolia.id,

  BSC = bsc.id,
  BSC_TESTNET = bscTestnet.id,
}

export const listNetwork: ListNetworkByChainId = {
  // [ChainIdEnum.ETH]: {
  //   code: 'ETH',
  //   info: mainnet,
  //   color: '#5a5e83',
  // },

  // [ChainIdEnum.SEPOLIA]: {
  //   code: 'SEPOLIA',
  //   info: sepolia,
  //   color: '#cb4241',
  // },

  [ChainIdEnum.BSC]: {
    code: 'BSC',
    info: {
      ...bsc,
      rpcUrls: {
        default: {
          http: ['https://bsc-dataseed.binance.org'],
        },
        public: {
          http: ['https://bsc-dataseed.binance.org'],
        },
      },

      name: 'BSC Mainnet',
    },
    color: '#5a5e83',
  },

  [ChainIdEnum.BSC_TESTNET]: {
    code: 'BSC_TESTNET',
    info: {
      ...bscTestnet,
      name: 'BSC Testnet',
    },
    color: '#cb4241',
  },
}

// TODO MAINNET
export const CHAIN_MAINNET = ChainIdEnum.BSC_TESTNET

// TODO MAINNET
export const NETWORK_IDS = [ChainIdEnum.BSC_TESTNET, ChainIdEnum.BSC]
