import { ModalProps } from 'components/Modal'

type LinkOfTextAndLink = string | { text: string; url: string }

type DeviceLink = {
  desktop?: LinkOfTextAndLink
  mobile?: LinkOfTextAndLink
}

export type LinkOfDevice = string | DeviceLink

export class WalletConnectorNotFoundError extends Error {}

export class WalletSwitchChainError extends Error {}

export type WalletConfig<T = unknown> = {
  id: string
  title: string
  icon: string | React.FC<React.PropsWithChildren<any>>
  connectorId: T
  deepLink?: string
  installed?: boolean
  guide?: LinkOfDevice
  downloadLink?: LinkOfDevice
  mobileOnly?: boolean
  qrCode?: () => Promise<string>
}

export interface WalletModalProps<T = unknown> extends ModalProps {
  wallets?: WalletConfig<T>[]
  docLink: string
  docText: string
}
