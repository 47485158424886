import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import type {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
} from '@reduxjs/toolkit/dist/matchers'
import { ChainIdEnum } from 'config/networks'
import { POOLS_MARKET } from 'config/pool'
import { Pool } from 'config/types/pool'
import stringify from 'fast-json-stable-stringify'
import {
  clearPoolUserData,
  fetchPoolUserDataAsync,
  fetchPoolsPublicDataAsync,
  fetchTokenPoolInUsdPrice,
} from './actions'

export interface PoolState {
  data: { [chainId in ChainIdEnum]?: Pool[] }
  loadingKeys: Record<string, boolean>
  userDataLoaded: boolean
}

const noAccountPoolConfig = Object.keys(POOLS_MARKET)
  .map((chainId) => ({
    [Number(chainId)]: POOLS_MARKET[chainId].map((pool) => ({
      ...pool,
      userData: {
        allowance: '0',
        tokenBalance: '0',
        suppliedBalance: '0',
      },
    })),
  }))
  .reduce((pre, cur) => ({ ...pre, ...cur }), {})

const initialState: PoolState = {
  data: noAccountPoolConfig,
  userDataLoaded: false,
  loadingKeys: {},
}
type UnknownAsyncThunkFulfilledOrPendingAction =
  | UnknownAsyncThunkFulfilledAction
  | UnknownAsyncThunkPendingAction
  | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
  action: UnknownAsyncThunkFulfilledOrPendingAction,
  suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
  const type = action.type.split(`/${suffix}`)[0]
  return stringify({
    arg: action.meta.arg,
    type,
  })
}

const reducer = createReducer<PoolState>(initialState, (builder) =>
  // Update pools with live data
  builder
    .addCase(fetchPoolsPublicDataAsync.fulfilled, (state, action) => {
      const { pools, chainId } = action.payload

      state.data[chainId] = state.data[chainId].map((pool) => {
        const liveFarmData = pools.find((poolData) => poolData.code === pool.code)
        return { ...pool, ...liveFarmData }
      })
    })
    .addCase(fetchTokenPoolInUsdPrice.fulfilled, (state, action) => {
      const { pools, chainId } = action.payload

      state.data[chainId] = state.data[chainId].map((pool) => {
        const liveFarmData = pools.find((poolData) => poolData.code === pool.code)
        return {
          ...pool,
          tokenInUsdPrice: liveFarmData.tokenInUsdPrice,
        }
      })
    })

    // Update pools with user data
    .addCase(fetchPoolUserDataAsync.fulfilled, (state, action) => {
      const { pools, chainId } = action.payload

      pools.forEach((userDataEl) => {
        const { code } = userDataEl
        const index = state.data[chainId].findIndex((pool) => pool.code === code)
        state.data[chainId][index] = { ...state.data[chainId][index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
    .addCase(clearPoolUserData, (state, action) => {
      state.data = initialState.data
      state.userDataLoaded = initialState.userDataLoaded
    })
    .addMatcher(isAnyOf(fetchPoolUserDataAsync.pending, fetchPoolsPublicDataAsync.pending), (state, action) => {
      state.loadingKeys[serializeLoadingKey(action as UnknownAsyncThunkFulfilledOrPendingAction, 'pending')] = true
    })
    .addMatcher(isAnyOf(fetchPoolUserDataAsync.fulfilled, fetchPoolsPublicDataAsync.fulfilled), (state, action) => {
      state.loadingKeys[serializeLoadingKey(action as UnknownAsyncThunkFulfilledOrPendingAction, 'fulfilled')] = false
    })
    .addMatcher(isAnyOf(fetchPoolsPublicDataAsync.rejected, fetchPoolUserDataAsync.rejected), (state, action) => {
      state.loadingKeys[serializeLoadingKey(action as UnknownAsyncThunkFulfilledOrPendingAction, 'rejected')] = false
    }),
)

export default reducer
