import React from 'react'
import styled, { css } from 'styled-components'
import Button from './Button'
import { ButtonProps } from './types'

const ButtonGroupItem: React.FC<
  ButtonProps & {
    $active?: boolean
  }
> = ({ ...props }) => {
  return <StyledButton {...props} />
}

const StyledButton = styled(Button)<{ $active?: boolean }>`
  flex: 1 1;
  border-radius: ${({ theme }) => theme.radii.small};

  background: transparent;
  font-size: 14px;
  height: 36px;
  padding: 8px 20px;
  white-space: nowrap;
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px;
    left: 0;
    background: ${({ theme }) => theme.colors.gradients.button};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  ${({ $active }) =>
    $active &&
    css`
      &:after {
        text-decoration: none;
        background-color: rgba(56, 61, 81, 0.04);
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    `}

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`
export default ButtonGroupItem
