import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import Image from 'components/Image/Image'
import Link from 'components/Link/Link'
import NetworkDropdown from 'components/NetworkDropdown'
import { navigationItems } from 'config/navigation'
import { TOKEN_FAUCET } from 'config/tokenList'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { RowFixed, RowMiddle } from './Row'
import UserFaucet from './UserFaucet'
import UserMenu from './UserMenu'
import { urlRoute } from 'config/route'
import Text from 'components/Text/Text'
import { css } from 'styled-components'
import MenuBar from './MenuBar'

const Topbar: React.FC = () => {
  const { chainId, isWrongChainId } = useActiveWeb3React()

  const isHaveFaucet = useMemo(() => !isWrongChainId && TOKEN_FAUCET[chainId]?.length > 0, [chainId, isWrongChainId])
  const router = useRouter()

  const refElement = useRef(null)

  useEffect(() => {
    if (refElement.current) {
      window.addEventListener('scroll', handleDetectScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleDetectScroll)
    }
  }, [refElement])

  const handleDetectScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop
    const topBarIsActive = refElement.current.classList.contains('active')
    const POSITION_SCROLL = 12
    if (top > POSITION_SCROLL) {
      if (!topBarIsActive) {
        refElement.current.classList.add('active')
      }
    } else {
      refElement.current.classList.remove('active')
    }
  }

  const renderDesktopMenu = useMemo(
    () => (
      <Grid
        display={['none !important', '', '', 'grid !important']}
        ml="40px"
        alignItems="center"
        gridTemplateColumns={`repeat(${navigationItems.length}, 1fr)`}
        gridGap="24px"
      >
        {navigationItems.map((nav) => {
          const pathName = router.pathname

          return (
            <StyledNav key={`navigation-${nav.label}`} $active={pathName === nav.href} href={nav.href}>
              {nav.label}
            </StyledNav>
          )
        })}
      </Grid>
    ),
    [router],
  )

  return (
    <StyledWrapBlankTopbar>
      <StyledTopbarContainer ref={refElement} p={['8px 12px', '', '', '12px 24px']}>
        <RowFixed>
          <StyledWrapLogo mt="4px" href={urlRoute.home().to}>
            <StyledLogo src="/images/logo.png" width={50} height={50} alt="logo-web" />
            <Text display={['none', '', 'block']} ml={['6px', '', '12px']} bold fontSize={['18px', '', '26px']}>
              Gentoo
            </Text>
          </StyledWrapLogo>
          {renderDesktopMenu}
        </RowFixed>
        <RowMiddle justifyContent="flex-end">
          {isHaveFaucet && (
            <Box mr="12px">
              <UserFaucet />
            </Box>
          )}

          <Box mr="8px">
            <NetworkDropdown />
          </Box>
          <UserMenu />
          <MenuBar ml="8px" />
        </RowMiddle>
      </StyledTopbarContainer>
    </StyledWrapBlankTopbar>
  )
}

const StyledWrapBlankTopbar = styled(Box)`
  width: 100%;
`
const StyledTopbarContainer = styled(Grid)`
  width: 100%;
  height: ${({ theme }) => theme.topbarHeight}px;
  grid-template-columns: auto auto;
  position: fixed;
  top: 0;
  z-index: ${({ theme }) => theme.zIndices.menu};

  &.active {
    background: ${({ theme }) => theme.colors.modalBackground} !important;
  }
`

const StyledNav = styled(Link)<{ $active: boolean }>`
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  text-align: center;
  margin: 0 auto;

  &:after {
    content: ' ';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -6px;
    left: 0;
    background: ${({ theme }) => theme.colors.gradients.button};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  ${({ $active }) =>
    $active &&
    css`
      &:after {
        text-decoration: none;
        background-color: rgba(56, 61, 81, 0.04);
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    `}

  &:hover {
    color: rgb(161 239 255);

    &:after {
      text-decoration: none;
      background-color: rgba(56, 61, 81, 0.04);
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`

const StyledWrapLogo = styled(Link)``
const StyledLogo = styled(Image)`
  width: 40px;
  height: 40px;
  transform: translateY(-4px);
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 50px;
    height: 50px;
  }
`
export default Topbar
