import { Colors } from './types'

export const baseColors = {
  failure: '#f59a86',
  primary: '#0077fb',
  primaryBright: '#85bfcb',
  secondary: '#FFB237',
  success: '#31D0AA',
  warning: '#FFB237',
}

export const additionalColors = {
  deepBlue: '#122145',
}

export const colors: Colors = {
  ...baseColors,
  ...additionalColors,
  button: '#0077fb',
  buttonTertiary: '#10162e',
  background: '#1e1d2b',
  backgroundAlt: '#252736',
  backgroundDisabled: '#1e1d2b',
  modalBackground: 'rgb(21, 27, 41)',
  secondBackground: 'rgba(4,9,28,0.8)',

  cardBackground: 'rgb(21, 27, 41)',

  text: '#fff',
  textDisabled: '#666171',
  textSubtle: '#969dad',
  textTertiary: '#e0e0e0',
  disabled: '#524B63',
  hover: 'rgba(255, 255, 255, 0.05)',

  input: 'rgb(35 46 67 / 88%)',
  tertiary: '#353547',
  gradients: {
    primary: `linear-gradient(to left, #0077fb, #0077fb)`,
    // button: `linear-gradient(90deg, ${baseColors.primaryBright} 50%,  ${baseColors.failure} 76%)`,
    button: `linear-gradient(248.86deg, ${baseColors.failure} 5.51%,  ${baseColors.primaryBright}  83.41%)`,
  },
}
