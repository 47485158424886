import { Provider } from '@ethersproject/providers'
import { CHAIN_MAINNET } from 'config/networks'
import { useChainId } from 'wagmi'
import useProvider from './useProvider'
import useWeb3 from './useWeb3'

export class ActiveWeb3React {
  chainId?: number
  isWrongChainId: boolean
  account: string
  provider: Provider
}

const useActiveWeb3React = (): ActiveWeb3React => {
  const { account, chain } = useWeb3()
  const provider = useProvider()
  const chainId = useChainId()
  // TODO MAINNET A

  const isWrongChainId = (chain && chainId !== chain.id) || chainId !== CHAIN_MAINNET

  return {
    isWrongChainId: isWrongChainId,
    chainId: isWrongChainId ? CHAIN_MAINNET : chainId,
    provider,
    account,
  }
}

export default useActiveWeb3React
