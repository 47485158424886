import { colors } from 'theme/colors'
import { variants, scales } from './types'
import theme from 'theme'

export const styleVariants = {
  [variants.PRIMARY]: {
    background: theme.colors.gradients.button,
    color: 'text',
  },
  [variants.PRIMARY_CONTRAST]: {
    background: colors.text,
    color: colors.primary,
  },
  [variants.PRIMARY_OUTLINE]: {
    background: 'transparent',
    color: colors.primary,
    border: '1px solid',
    borderColor: 'primary',
  },
  [variants.SECONDARY]: {
    background: 'textSubtle',
    border: '1px solid',
    borderColor: 'textSubtle',
    boxShadow: 'none',
    color: 'text',
    ':disabled': {
      background: 'transparent',
    },
  },
  [variants.SUBTLE]: {
    background: 'transparent',
    color: 'text',
    boxShadow: 'none',
  },
  [variants.TERTIARY]: {
    boxShadow: 'none',
  },
  [variants.TEXT]: {
    background: 'transparent',
    color: 'primary',
    boxShadow: 'none',
  },
  [variants.DANGER]: {
    background: 'failure',
    color: 'text',
    boxShadow: 'none',
  },
}
export const styleScales = {
  [scales.LG]: {
    height: '55px',
    borderRadius: theme.radii.small,
  },
  [scales.MD]: {
    height: '50px',
    borderRadius: theme.radii.default,
  },
  [scales.SM]: {
    height: '44px',
    borderRadius: theme.radii.small,
  },
  [scales.XS]: {
    height: '36px',
    borderRadius: theme.radii.small,
  },
}
