import { ZERO_ADDRESS } from 'config'
import { ChainIdEnum } from './networks'

// ADD NETWORK
const addresses = {
  [ChainIdEnum.SEPOLIA]: {
    MULTICALL: '0x5bd57e601C70869d334C481a09d06d82dc7D2C63',
    ORACLE: '0x77f3f06aC3d09218d22A318e5E65515248d10a59',
    WETH_GATEWAY: ZERO_ADDRESS,

    WBNB: ZERO_ADDRESS,
    USDC: ZERO_ADDRESS,
    BUSD: ZERO_ADDRESS,
    USDT: ZERO_ADDRESS,

    LIQUIDITY_POOL: ZERO_ADDRESS,

    brUSDC: ZERO_ADDRESS,
    brBUSD: ZERO_ADDRESS,
    brUSDT: ZERO_ADDRESS,
  },

  [ChainIdEnum.BSC]: {
    MULTICALL: '0xff6fd90a470aaa0c1b8a54681746b07acdfedc9b',
    ORACLE: '0x17aee0686f682b7ef8ac521fde5fdb654062a5f2',
    WETH_GATEWAY: '0x83B9A0CAC438125E23D981C8FC8D6ef95ecb2D6A',

    WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    ETH: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    USDT: '0x55d398326f99059ff775485246999027b3197955',
    BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    USDC: ZERO_ADDRESS, // TODO
    VAI: ZERO_ADDRESS,
    XVS: ZERO_ADDRESS,

    LIQUIDITY_POOL: '0x7d6f674451e2e5261504da097e827e147c78dbe7',

    brUSDT: '0xE7A4B757d33E594F68c4CA1e2102f221903dd503',
    brBUSD: '0x8210bcD82E2C3F8Ea21803c0c0c69F3d1D839629',
    brUSDC: ZERO_ADDRESS, // TODO

    ankrBNB: ZERO_ADDRESS, // TODO
    ankrETH: ZERO_ADDRESS, // TODO
    WOM: ZERO_ADDRESS, // TODO
    MGP: ZERO_ADDRESS, // TODO
  },

  [ChainIdEnum.BSC_TESTNET]: {
    MULTICALL: '0xa6949b8fba9df546b9c66f98cfca960a96e3b68e',
    ORACLE: '0x331fAe28bd66F3D2818b9BC41Ed33F827858Ab4D',
    WETH_GATEWAY: '0x9a1e603645197a19c0ae8107a05f7096503d75a2',

    WBNB: '0x210b907d269c5cf76972a621aea5dd83f62d37c0',
    ETH: '0xf72C0EA6E12c12818a541a29a0F04F9C1322D2e4',
    USDT: '0x4224d18448b5e88f7d83e053cffb44a9078b05f0',
    BUSD: '0x74dbD2AAD5F894317ddC54457b144daF61003BFb',
    USDC: '0xA0ceAeACedE08dCCdd457eB7ad072ceB2e8CD100',
    VAI: '0x49182a87C389909E124665Bc1FC2eDC54a65Dfe0',
    XVS: '0xfeDdc32b232087915596d402fc09da8c1D546A12',
    BSW: '0x94253B48A75D8dD3c8Be16e1d41a86354947FAB6',

    LIQUIDITY_POOL: '0x622cFBd7E078e5434cb73E8312ad15C9d19e0881',

    brUSDT: '0x8E9311B4ebD8cC7d576D13fD50D43662A209185C',
    brBUSD: '0x40C7bF85a0d1fd2b657125396290d62e5f3157b2',
    brUSDC: '0x35Cd69ED4836DB8844B788f555ABBdCF972d5378',

    ankrBNB: ZERO_ADDRESS, // TODO
    ankrETH: ZERO_ADDRESS, // TODO
    WOM: ZERO_ADDRESS, // TODO
    MGP: ZERO_ADDRESS, // TODO
  },
}

export type AddressBook = typeof addresses
export type AddressNames = (typeof addresses)[keyof typeof addresses]
export default addresses
