import Box from 'components/Box/Box'
import BoxGradient from 'components/Box/BoxGradient'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Link from 'components/Link/Link'
import Dropdown from 'components/Dropdown/Dropdown'
import { navigationItems, socials } from 'config/navigation'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { MenuBarIcon } from 'svgs'
import Column from './Column'
import { Position } from 'components/Popper/typpes'

const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0;
`

const StyledFunctionContainer = styled(Link)<{ $active: boolean }>`
  transition: ${({ theme }) => theme.transitions.fast};
  width: 100%;

  cursor: pointer;

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.primaryBright} !important;
    `}

  :hover {
    background: ${({ theme }) => theme.colors.hover};
  }
`

const StyledWrapSocial = styled(Column)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.textDisabled};

  ${({ theme }) => theme.mediaQueries.lg} {
    border-top: 0;
  }
`

const AccountDropdown: React.FC<
  BoxProps & {
    dropdownPosition?: Position
    offsetBottom?: number
  }
> = ({ dropdownPosition = 'bottom-right', offsetBottom, ...props }) => {
  const router = useRouter()
  const { isDesktop } = useMatchBreakpoints()

  const DropdownContent: React.FC<{ onDismiss?: () => void }> = ({ onDismiss }) => (
    <Flex flexDirection="column" minWidth="200px" maxWidth="400px">
      {!isDesktop &&
        navigationItems.map((nav) => {
          const pathName = router.pathname

          return (
            <StyledFunctionContainer
              $active={pathName === nav.href}
              key={`network-item-${nav.label}`}
              p="16px"
              href={nav.href}
              onClick={onDismiss}
            >
              {nav.label}
            </StyledFunctionContainer>
          )
        })}

      <StyledWrapSocial>
        {socials.map((nav) => {
          const pathName = router.pathname

          return (
            <StyledFunctionContainer
              $active={pathName === nav.href}
              key={`network-item-${nav.label}`}
              p="16px"
              href={nav.href}
              external
              onClick={onDismiss}
            >
              {nav.label}
            </StyledFunctionContainer>
          )
        })}
      </StyledWrapSocial>
    </Flex>
  )

  return (
    <StyledDropdown
      p="0 !important"
      offsetBottom={offsetBottom}
      dropdownPosition={dropdownPosition}
      dropdownContent={<DropdownContent />}
      {...props}
    >
      <BoxGradient height="36px" width="36px">
        <MenuBarIcon width="16px" height="16px" />
      </BoxGradient>
    </StyledDropdown>
  )
}

export default React.memo(AccountDropdown)
