import { useState, useEffect, useMemo, useRef } from 'react'
import { useBid } from './useBid'
import { CoinType } from 'dotbit'
import { ChainIdEnum } from 'config/networks'

export const useBidName = (address: string) => {
  const [loading, setLoading] = useState(false)
  const [bidName, setBidName] = useState(undefined)
  const refAccount = useRef('')
  const bid = useBid()

  const getName = async () => {
    setLoading(true)
    try {
      const data = await bid.reverse({
        coin_type: CoinType.BSC,
        chain_id: ChainIdEnum.BSC.toString(),
        key: address,
      })

      setBidName(data?.account || null)
    } catch {
      setBidName(null)
    }
    refAccount.current = address
    setLoading(false)
  }
  useEffect(() => {
    if (address && bid && refAccount.current !== address) {
      getName()
    }
  }, [address, bid])

  return useMemo(
    () => ({
      loading,
      bidName,
    }),
    [loading, bidName],
  )
}
