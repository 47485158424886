export type UnitEther = 'wei' | 'kwei' | 'mwei' | 'gwei' | 'szabo' | 'finney' | 'ether'
export const unitsEther: { [key in UnitEther]: number } = {
  wei: 0,
  kwei: 3,
  mwei: 6,
  gwei: 9,
  szabo: 12,
  finney: 15,
  ether: 18,
}

export const KWEI_NUMBER = 1 * 10 ** unitsEther.kwei
export const MWEI_NUMBER = 1 * 10 ** unitsEther.mwei
export const GWEI_NUMBER = 1 * 10 ** unitsEther.gwei
export const SZABO_NUMBER = 1 * 10 ** unitsEther.szabo
export const FINNEY_NUMBER = 1 * 10 ** unitsEther.finney
export const ETHER_NUMBER = 1 * 10 ** unitsEther.ether
