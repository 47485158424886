import { Interface } from '@ethersproject/abi'
import ERC20_ABI from './erc20.json'
import ERC20_FAUCET_ABI from './erc20-faucet.json'
import LIQUIDITY_POOL_ABI from './liquidity-pool.json'
import MULTICALL_ABI from './Multicall.json'
import ORACLE_ABI from './oracle.json'
import STRATEGY_BOX_ABI from './strategy-box.json'
import WETH_GATEWAY_ABI from './weth-gateway.json'

const IERC20 = new Interface(ERC20_ABI)
const IERC20_FAUCET = new Interface(ERC20_FAUCET_ABI)

const ILIQUIDITY_POOL = new Interface(LIQUIDITY_POOL_ABI)
const IMULTICALL = new Interface(MULTICALL_ABI)
const IORACLE = new Interface(ORACLE_ABI)
const ISTRATEGY_BOX = new Interface(STRATEGY_BOX_ABI)
const IWETH_GATEWAY = new Interface(WETH_GATEWAY_ABI)

export {
  ERC20_ABI,
  IERC20,
  ERC20_FAUCET_ABI,
  IERC20_FAUCET,
  LIQUIDITY_POOL_ABI,
  ILIQUIDITY_POOL,
  MULTICALL_ABI,
  IMULTICALL,
  ORACLE_ABI,
  IORACLE,
  STRATEGY_BOX_ABI,
  ISTRATEGY_BOX,
  WETH_GATEWAY_ABI,
  IWETH_GATEWAY,
}
