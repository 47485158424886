import { APIEnum } from './ApiEnum'
import { GentooYieldRequest } from './baseRequest'
import { BaseResponse, BoxInfoResponse, GentooRequest } from './types'

class BoxService extends GentooYieldRequest {
  public getBoxesInfo(): Promise<BaseResponse<BoxInfoResponse[]>> {
    const result = this._get(APIEnum.boxInfo)
    return result
  }
}

const instance = new BoxService()
export default instance
