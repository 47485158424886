import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Dropdown from 'components/Dropdown'
import useAuth from 'hooks/useAuth'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import BoxGradient from 'components/Box/BoxGradient'
import Image from 'components/Image/'
import { ChainIdEnum, listNetwork } from 'config/networks'
import { Network } from 'config/types/network'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { RowMiddle } from 'layout/components/Row'
import { ConnectorNames } from 'packages/wagmi/wallet'
import { WarningIcon } from 'svgs'
import theme from 'theme'
import { getIconChain } from 'utils/networkHelper'
import { useAccount, useChainId, useNetwork, useSwitchNetwork } from 'wagmi'
import Text from '../Text'

const StyledBoxGradient = styled(BoxGradient)`
  box-sizing: border-box;
  padding: 8px 12px;
  height: 40px;
`

const WrapperNetworkItem = styled(Flex)`
  transition: ${({ theme }) => theme.transitions.fast};
  cursor: pointer;
  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
    margin-right: 8px;
  }

  :hover {
    background: ${({ theme }) => theme.colors.hover};

    ${Text} {
      color: ${({ theme }) => theme.colors.text};
    }

    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`
const NetworkItem: React.FC<{ network: Network; selected?: boolean } & BoxProps> = ({
  network,
  selected,
  ...props
}) => {
  const chainIcon = useMemo(() => getIconChain(network?.info.id), [network])
  return (
    <RowMiddle flex={1} width="100%" {...props}>
      <Box width={24} height={24}>
        <Image src={chainIcon} width={24} height={24} alt="network-icon" />
      </Box>

      <Text
        display={selected ? ['none !important', '', '', '', 'block !important'] : ''}
        color={selected ? 'text' : 'textSubtle'}
        ml="8px"
      >
        {network?.info.name}
      </Text>
    </RowMiddle>
  )
}
const NetworkDropdown: React.FC<BoxProps> = ({ ...props }) => {
  const chainId = useChainId()

  const network = useNetwork()

  const { switchNetwork } = useSwitchNetwork()
  const { login } = useAuth()
  const { isConnected } = useAccount()
  const { isMobile } = useMatchBreakpoints()
  const { isWrongChainId } = useActiveWeb3React()

  const listNetworkDropdown = useMemo(
    () => [ChainIdEnum.BSC_TESTNET].map((id) => listNetwork?.[id]).filter((item) => item),
    [chainId],
  )

  const handleSwitchNetwork = useCallback(
    async (networkConfig: Network) => {
      if (networkConfig.info.id !== chainId || network.chain?.id !== chainId) {
        if (isConnected && switchNetwork) {
          switchNetwork(networkConfig.info.id)
        } else {
          login(ConnectorNames.Injected, networkConfig.info.id).catch(() => {})
        }
      }
    },
    [isConnected, login, chainId, network, switchNetwork],
  )

  const DropdownContent = useMemo(() => {
    return (
      <Flex flexDirection="column" minWidth="200px" maxWidth="400px">
        {listNetworkDropdown.map((networkConfig) => {
          return (
            <WrapperNetworkItem
              key={`network-item-${networkConfig.info.id}`}
              p="16px"
              alignItems="center"
              onClick={() => handleSwitchNetwork(networkConfig)}
            >
              <NetworkItem network={networkConfig} />
            </WrapperNetworkItem>
          )
        })}
      </Flex>
    )
  }, [chainId, handleSwitchNetwork])

  const WrongNetwork = () => {
    return (
      <RowMiddle flex={1} width="100%" {...props}>
        <Box width={24} height={24}>
          <WarningIcon fill={theme.colors.failure} />
        </Box>

        <Text color="failure" ml="8px">
          {isMobile ? 'Network' : 'Wrong Network'}
        </Text>
      </RowMiddle>
    )
  }

  return (
    <StyledBoxGradient>
      <Dropdown
        p="6px 8px !important"
        dropdownContent={listNetworkDropdown.length > 1 || isWrongChainId ? DropdownContent : null}
        dropdownPosition={isMobile ? 'bottom-left' : 'bottom-right'}
        {...props}
      >
        {isWrongChainId ? <WrongNetwork /> : <NetworkItem selected network={listNetwork[chainId]} />}
      </Dropdown>
    </StyledBoxGradient>
  )
}

export default React.memo(NetworkDropdown)
