import { createReducer } from '@reduxjs/toolkit'
import { StrategyAsset, StrategyProtocol } from 'config/types/strategy'
import { setBoxesInfo, setStrategyAsset, setStrategyProtocol } from './actions'
import { StrategyState } from './types'

const initialState: StrategyState = {
  protocol: StrategyProtocol.AnyProtocol,
  asset: StrategyAsset.AnyAsset,
  boxInfo: [],
}

// export const strategyTransform = createTransform(
//   (inboundState: any, key) =>
//     customTransformPersist<TransformInbound<any, any, any>>(inboundState, key as string, 'strategy', [
//       'protocol',
//       'asset',
//     ]),
//   (outBoundState: any, key) =>
//     customTransformPersist<TransformOutbound<any, any, any>>(outBoundState, key as string, 'strategy', [
//       'protocol',
//       'asset',
//     ]),
// )

const reducer = createReducer<StrategyState>(initialState, (builder) =>
  builder
    .addCase(setStrategyProtocol, (state, { payload }) => ({
      ...state,
      protocol: payload,
    }))

    .addCase(setStrategyAsset, (state, { payload }) => ({
      ...state,
      asset: payload,
    }))
    .addCase(setBoxesInfo, (state, { payload }) => ({
      ...state,
      boxInfo: payload,
    })),
)

export default reducer
