import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Grid from 'components/Box/Grid'
import Modal from 'components/Modal/Modal'
import styled, { keyframes } from 'styled-components'
import { css } from 'styled-components'

const promotedGradientKf = keyframes({
  '0%': {
    backgroundPosition: '50% 0%',
  },
  '50%': {
    backgroundPosition: '50% 100%',
  },
  '100%': {
    backgroundPosition: '50% 0%',
  },
})

export const ModalWrapperClass = styled(Flex)`
  width: 100%;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.modal};
  height: 490px;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 792px;
  }
`

export const DesktopWalletSelectionClass = styled(Flex)`
  width: 100%;
  height: 100%;

  padding: 12px 0;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 480px;
  }
`

export const WalletSelectWrapperClass = styled(Grid)`
  row-gap: 24px;
  column-gap: 16px;
  height: 100%;

  grid-template-columns: 1fr 1fr 1fr;
  overflow-x: hidden;
  overflow-y: overlay;

  ${({ theme }) => theme.mediaQueries.xs} {
    row-gap: 10px;
    column-gap: 16x;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    row-gap: 24px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const walletIconClass = styled(Box)`
  width: '50px';
  height: '50px';
  border-radius: '12px';
`

export const WrapperModalWallet = styled(Modal)`
  position: relative;
  box-sizing: border-box;
  overflow: initial;
  z-index: ${({ theme }) => theme.zIndices.modal};
  height: 490px;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 792px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 792px;
  }
`

export const StyledWrapButtonGroupItem = styled(Flex)`
  button {
    font-size: 10px;
    border-bottom-left-radius: 0;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    ${({ theme }) => theme.mediaQueries.xs} {
      font-size: 12px;
    }

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 14px;
    }
  }
`
export const PromotedGradientClass = styled(Box)<{ $installed: boolean }>`
  ${({ $installed }) =>
    $installed &&
    css`
      background: linear-gradient(#53dee9, #7645d9);
      animation: ${promotedGradientKf} 3s ease infinite;
      background-size: '400% 400%';
    `}
`
