import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Store } from '@reduxjs/toolkit'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import theme from 'theme'

import ModalsProvider from 'contexts/Modals'
import { ToastsProvider } from 'contexts/ToastsContext/Provider'
import { SWRConfig } from 'swr'
import { WagmiProvider } from 'packages/wagmi/provider'
import { client } from 'packages/wagmi/wagmi'

const Providers: React.FC<{ store: Store; children: React.ReactNode }> = ({ children, store }) => {
  return (
    <WagmiProvider client={client}>
      <Provider store={store}>
        <ToastsProvider>
          <ThemeProvider theme={theme}>
            <SWRConfig
              value={{
                use: [fetchStatusMiddleware],
              }}
            >
              <ModalsProvider>{children}</ModalsProvider>
            </SWRConfig>
          </ThemeProvider>
        </ToastsProvider>
      </Provider>
    </WagmiProvider>
  )
}

export default Providers
