import { Strategy } from 'config/types/strategy'
import { useCallback, useEffect, useMemo } from 'react'
import BoxService from 'services/requests/BoxService'
import { BoxInfoResponse } from 'services/requests/types'
import { useAppDispatch, useAppSelector } from 'state'
import { setBoxesInfo } from 'state/strategy/actions'
import { useSlowRefreshEffect } from './useRefreshEffect'
import useActiveWeb3React from './useActiveWeb3React'
import { ChainIdEnum } from 'config/networks'

export const useFetchBoxInfo = () => {
  const { chainId } = useActiveWeb3React()

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    const result = await BoxService.getBoxesInfo()
    if (result?.data?.length > 0) dispatch(setBoxesInfo(result.data))
  }, [dispatch])

  useSlowRefreshEffect(() => {
    if (chainId === ChainIdEnum.BSC) fetch()
  }, [chainId])
}
export const useBoxesInfo = () => {
  const boxInfo = useAppSelector((state) => state.strategy.boxInfo)
  return useMemo(() => boxInfo, [boxInfo])
}

export const useBoxInfo = (strategyBox: Strategy): BoxInfoResponse => {
  const boxesInfo = useBoxesInfo()

  return useMemo(
    () =>
      boxesInfo?.find(
        (box) =>
          box.address?.toLowerCase() === strategyBox.address.toLowerCase() &&
          box.name?.toLowerCase() === strategyBox.code.toLowerCase(),
      ) || ({} as BoxInfoResponse),
    [boxesInfo, strategyBox],
  )
}
