import { TextProps } from 'components/Text/types'
import NextLink from 'next/link'
import React, { forwardRef } from 'react'

// react-router-dom LinkProps types
interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: any
  replace?: boolean
  innerRef?: React.Ref<HTMLAnchorElement>
  // next
  prefetch?: boolean
}

/**
 * temporary solution for migrating React Router to Next.js Link
 */
export const NextLinkFromReactRouter = forwardRef<any, LinkProps>(
  ({ to, replace, children, prefetch, small, ellipsis, ...props }: any, ref) => (
    <NextLink ref={ref} {...props} href={to as string} replace={replace} passHref prefetch={prefetch}>
      {children}
    </NextLink>
  ),
)
