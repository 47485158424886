import { useMemo } from 'react'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import app, { AppState as IAppState } from './app/reducer'
import transactions, { TransactionState } from './transactions/reducer'
import multicall, { MulticallState } from './multicall/reducer'
import updateVersion from './global/actions'
import block, { BlockState } from './block'
import portfolio from './portfolio'
import pools, { PoolState } from './pools'
import user, { UserState } from './user/reducer'
import strategy from './strategy/reducer'

import { StrategyState } from './strategy/types'
import { PortfolioState } from './portfolio/types'

const PERSISTED_KEYS: string[] = ['transactions', 'user']

const persistConfig = {
  key: 'gentoo',
  whitelist: PERSISTED_KEYS,
  storage,
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    app,
    transactions,
    multicall,
    block,
    pools,
    strategy,
    portfolio,
    user,
  }),
)

let store: ReturnType<typeof makeStore>

export function makeStore(preloadedState = undefined) {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools: process.env.NODE_ENV === 'development',
    preloadedState,
  })
}

export const initializeStore = (preloadedState = undefined) => {
  let _store = store ?? makeStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store

  // Create the store once in the client
  if (!store) {
    store = _store
  }

  return _store
}

store = initializeStore()

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = {
  app: IAppState
  transactions: TransactionState
  multicall: MulticallState
  block: BlockState
  pools: PoolState
  strategy: StrategyState
  portfolio: PortfolioState
  user: UserState
}

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store

export const persistor = persistStore(store, undefined, () => {
  store.dispatch(updateVersion())
})

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState])
}
