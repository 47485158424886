import { ChainIdEnum } from 'config/networks'
import { useChainId, useProvider as useWagmiProvider } from 'wagmi'

const useProvider = (replacedChainId?: ChainIdEnum) => {
  const chainId = useChainId()

  if (!chainId) return

  const provider = useWagmiProvider({ chainId: replacedChainId || chainId })

  return provider
}

export default useProvider
