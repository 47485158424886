import Box from 'components/Box/Box'
import { Position, PositionProps } from 'components/Popper/typpes'
import React, { useState } from 'react'
import { animated } from 'react-spring'
import styled, { css } from 'styled-components'
import { DropdownProps } from './types'
import OpenEffect from 'components/OpenEffect'

const getLeft = ({ position }: PositionProps) => {
  if (position === 'top-right') {
    return 'unset'
  }

  if (position === 'top-left') {
    return '0%'
  }

  if (position === 'bottom-left') {
    return '0%'
  }

  if (position === 'bottom-right') {
    return 'unset'
  }

  return '50%'
}

const getRight = ({ position }: PositionProps) => {
  if (position === 'bottom-right' || position === 'top-right') {
    return '0%'
  }

  return 'unset'
}

const getTransformWhenActive = ({ position }: any) => {
  if (
    position === 'bottom-left' ||
    position === 'bottom-right' ||
    position === 'top-left' ||
    position === 'top-right'
  ) {
    return 'translate(0%, 0%)'
  }
  return 'translate(-50%, 0%)'
}

const getBottom = ({ position }: PositionProps) => {
  if (position === 'top' || position === 'top-right' || position === 'top-left') {
    return '100%'
  }
  return 'auto'
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  dropdownPosition,
  dropdownContent,
  offsetTop = 8,
  offsetBottom = 0,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Box
      width="auto"
      height="auto"
      position="relative"
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
    >
      <StyledAnchorWrapper {...props}>{children}</StyledAnchorWrapper>

      {dropdownContent && (
        <StyledDropdownWrapper $open={open} position={dropdownPosition}>
          <OpenEffect openType="fade">
            <StyledWrapContent offsetBottom={offsetBottom} offsetTop={offsetTop}>
              <StyledDropdownContent>
                {React.isValidElement(dropdownContent) ? (
                  React.cloneElement(dropdownContent, {
                    onDismiss: () => setOpen(false),
                  } as any)
                ) : (
                  <>{dropdownContent}</>
                )}
              </StyledDropdownContent>
            </StyledWrapContent>
          </OpenEffect>
        </StyledDropdownWrapper>
      )}
    </Box>
  )
}

const StyledDropdownWrapper = styled(animated.div)<{ position: Position; $open: boolean }>`
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  bottom: ${getBottom};
  left: ${getLeft};
  right: ${getRight};

  display: flex;
  transform: ${getTransformWhenActive} !important;
  overflow: visible;
  min-width: 100%;
  width: max-content;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  margin-top: 20px;

  width: max-content;
  display: flex;
  flex-direction: column;
  position: absolute;

  max-height: 400px;
  transition: ${({ theme }) => theme.transitions.fast};

  ${({ $open }) =>
    $open &&
    css`
      margin-top: 0px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`
const StyledAnchorWrapper = styled(Box)`
  cursor: pointer;
  padding: 6px;
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  transition: ${({ theme }) => theme.transitions.fast};
  border-radius: ${({ theme }) => theme.radii.small};
  background-color: ${({ theme }) => theme.colors.backgroundAlt};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 6px 16px;
  }
`

const StyledWrapContent = styled(Box)<{ offsetTop: number; offsetBottom?: number }>`
  margin-top: ${({ offsetTop }) => offsetTop}px;
  margin-bottom: ${({ offsetBottom = 0 }) => offsetBottom}px;
  border-radius: ${({ theme }) => theme.radii.default};

  backdrop-filter: blur(6px);
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.2);

  overflow: hidden;
`

const StyledDropdownContent = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: ${({ theme }) => theme.radii.default};
`

export default Dropdown
