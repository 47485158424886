import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import ToastDescriptionWithTx from 'components/Toast/DescriptionWithTx'
import useToast from 'hooks/useToast'
import { AppDispatch } from '../index'
import { finalizeTransaction } from './actions'
import { useAllChainTransactions } from './hooks'
import useProvider from 'hooks/useProvider'
import { TransactionDetails } from 'config/types/transaction'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { forEach, merge, pickBy } from 'lodash'
import { poll } from '@ethersproject/web'

export function shouldCheck(
  fetchedTransactions: { [txHash: string]: TransactionDetails },
  tx: TransactionDetails,
): boolean {
  if (tx.receipt) return false
  return !fetchedTransactions[tx.hash]
}

export default function Updater() {
  const dispatch = useDispatch<AppDispatch>()

  const transactions = useAllChainTransactions()

  const { toastError, toastSuccess, clear } = useToast()

  const fetchedTransactions = useRef<{ [txHash: string]: TransactionDetails }>({})
  const { chainId } = useActiveWeb3React()
  const provider = useProvider()

  useEffect(() => {
    if (!provider) return

    forEach(
      pickBy(transactions, (transaction) => shouldCheck(fetchedTransactions.current, transaction)),
      (transaction) => {
        const getTransaction = async () => {
          await provider.getNetwork()

          const params = { transactionHash: provider.formatter.hash(transaction.hash, true) }

          poll(
            async () => {
              const result = await provider.perform('getTransactionReceipt', params)

              if (result == null || result.blockHash == null) {
                return undefined
              }

              const receipt = provider.formatter.receipt(result)

              dispatch(
                finalizeTransaction({
                  chainId,
                  hash: transaction.hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                }),
              )

              const isSuccess = receipt.status === 1

              const toast = isSuccess ? toastSuccess : toastError

              clear()
              toast(
                `Transaction ${isSuccess ? 'Succeed' : 'Failed'}`,
                <ToastDescriptionWithTx txHash={receipt.transactionHash} chainId={chainId} />,
              )
              return true
            },
            { onceBlock: provider },
          )
          merge(fetchedTransactions.current, { [transaction.hash]: transactions[transaction.hash] })
        }

        getTransaction()
      },
    )
  }, [chainId, provider, transactions, dispatch, toastSuccess, toastError])

  return null
}
