import { ChainIdEnum } from './networks'
import { TOKENS } from './tokens'
import { Pool } from './types/pool'

// ADD NETWORK
export const POOLS_MARKET: { [id in ChainIdEnum]: Pool[] } = {
  [ChainIdEnum.BSC_TESTNET]: [
    // {
    //   code: TOKENS[ChainIdEnum.BSC_TESTNET].USDC.symbol,
    //   suppliedToken: TOKENS[ChainIdEnum.BSC_TESTNET].brUSDC,
    //   token: TOKENS[ChainIdEnum.BSC_TESTNET].USDC,
    // },
    {
      code: TOKENS[ChainIdEnum.BSC_TESTNET].USDT.symbol,
      suppliedToken: TOKENS[ChainIdEnum.BSC_TESTNET].brUSDT,
      token: TOKENS[ChainIdEnum.BSC_TESTNET].USDT,
    },
    {
      code: TOKENS[ChainIdEnum.BSC_TESTNET].BUSD.symbol,
      suppliedToken: TOKENS[ChainIdEnum.BSC_TESTNET].brBUSD,
      token: TOKENS[ChainIdEnum.BSC_TESTNET].BUSD,
    },
  ],

  [ChainIdEnum.BSC]: [
    {
      code: TOKENS[ChainIdEnum.BSC].USDT.symbol,
      suppliedToken: TOKENS[ChainIdEnum.BSC].brUSDT,
      token: TOKENS[ChainIdEnum.BSC].USDT,
    },
    {
      code: TOKENS[ChainIdEnum.BSC].BUSD.symbol,
      suppliedToken: TOKENS[ChainIdEnum.BSC].brBUSD,
      token: TOKENS[ChainIdEnum.BSC].BUSD,
    },
  ],
}
